/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import { compose, AnyAction } from "redux";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { t } from "i18n";

import WrapPage from "containers/page/page";
import ContentHeader from "pages/settings/components/content-header/content-header";
import FieldLabel from "components/field-label/field-label";
import ProseMirrorTextArea from "components/prosemirror-text-area/prosemirror-text-area";
import HeroImage from "pages/story-editor/components/hero-image/hero-image";
import BreakingNewsDefaultsInspector from "./inspector";
import Spinner from "components/spinner/spinner";
import Header from "./header";

import { navigateFn } from "utils/routes.utils";
import { BREAKING_NEWS_DEFAULTS_IMAGE_GALLERY, CONFIGURE_BREAKING_NEWS_PATH } from "pages/settings/routes";
import { loadBreakingNewsDefaults, updateAssociatedStoryDefaults } from "./async-action-creators";
import { PartialAppState } from "./state";
import { AssociatedStoryDefaults, ValidationErrors } from "api/breaking-news-defaults";
import { AnyImage } from "api/search-media-image";

import { setText, getErrorMessage, setImage, getHeroImageFieldErrors } from "./utils";
import classnames from "classnames/bind";
import styles from "./breaking-news-defaults.module.css";

const cx = classnames.bind(styles);
interface StateProps {
  loading: boolean;
  associatedStoryDefaults: AssociatedStoryDefaults;
  isAssociatedStoryDefaultsModified: boolean;
  errors: ValidationErrors | null;
  title: string;
}

interface DispatchProps {
  openGallery: () => void;
  closeGallery: () => void;
  loadBreakingNewsDefaults: () => void;
  updateAssociatedStoryDefaults: (associatedStoryDefaults: AssociatedStoryDefaults) => void;
  setSelectedMedia: (associatedStoryDefaults: AssociatedStoryDefaults, image: any) => void;
}

type Props = {
  showMediaGallery: boolean;
  isBannerPresent: boolean;
} & StateProps &
  DispatchProps;

class BreakingNewsDefaults extends React.Component<Props> {
  componentDidMount() {
    this.props.loadBreakingNewsDefaults();
  }

  render() {
    const { showMediaGallery, openGallery, loading, associatedStoryDefaults, errors } = this.props;

    const imageData =
      associatedStoryDefaults && associatedStoryDefaults["image-data"] ? associatedStoryDefaults["image-data"] : null;
    const image = imageData
      ? {
          url: imageData["image-url"],
          metadata: imageData["image-metadata"],
          attribution: imageData["image-attribution"],
          caption: imageData["image-caption"],
          key: imageData["image-s3-key"] ? imageData["image-s3-key"] : null,
          "temp-image-key": imageData["temporary-image-key"] ? imageData["temporary-image-key"] : null,
          "alt-text": imageData["alt-text"]
        }
      : null;

    return (
      <React.Fragment>
        <main className={cx("breaking-news-page-container", "breaking-news-defaults")}>
          <ContentHeader
            title={t("settings.breaking-news-defaults.page-title")}
            description={t("settings.breaking-news-defaults.page-description")}
          />
          {loading ? (
            <Spinner message={t("settings.breaking-news-defaults.loading-message")} />
          ) : (
            <React.Fragment>
              <div className={styles["breaking-news-form-container"]}>
                <ProseMirrorTextArea
                  onChange={(htmlString) =>
                    this.props.updateAssociatedStoryDefaults(setText(associatedStoryDefaults, htmlString))
                  }
                  value={associatedStoryDefaults && associatedStoryDefaults.text}
                  label={t("settings.breaking-news-defaults.content-label")}
                  errorMessage={errors && errors["text"] ? getErrorMessage(errors["text"]) : ""}
                />
                <FieldLabel label={t("settings.breaking-news-defaults.hero-image-label")} />
                <HeroImage
                  openMediaGallery={openGallery}
                  image={image}
                  updateImageData={(image: AnyImage) =>
                    this.props.updateAssociatedStoryDefaults(setImage(associatedStoryDefaults, image))
                  }
                  errors={errors ? getHeroImageFieldErrors(errors) : null}
                  setSelectedImage={(images: AnyImage[]) =>
                    this.props.setSelectedMedia(associatedStoryDefaults, images[0])
                  }
                  isFocusPointMandatory={true}
                />
              </div>
              <BreakingNewsDefaultsInspector
                isActive={showMediaGallery}
                closeGallery={this.props.closeGallery}
                setSelectedMedia={(images: any[]) => this.props.setSelectedMedia(associatedStoryDefaults, images[0])}
              />
            </React.Fragment>
          )}
        </main>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: PartialAppState) => {
  return {
    loading: state.breakingNewsDefaults.ui.main.loading,
    associatedStoryDefaults: state.breakingNewsDefaults.associatedStoryDefaults,
    isAssociatedStoryDefaultsModified: state.breakingNewsDefaults.ui.isAssociatedStoryDefaultsModified,
    errors: state.breakingNewsDefaults.ui.errors,
    title: "configure_breaking_news_defaults"
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>): DispatchProps => {
  const navigate = navigateFn(dispatch);
  return {
    openGallery: () => navigate(BREAKING_NEWS_DEFAULTS_IMAGE_GALLERY),
    closeGallery: () => navigate(CONFIGURE_BREAKING_NEWS_PATH),
    loadBreakingNewsDefaults: () => dispatch(loadBreakingNewsDefaults()),
    updateAssociatedStoryDefaults: (associatedStoryDefaults) =>
      dispatch(updateAssociatedStoryDefaults(associatedStoryDefaults)),
    setSelectedMedia: (associatedStoryDefaults, image) => {
      dispatch(updateAssociatedStoryDefaults(setImage(associatedStoryDefaults, image)));
      navigate(CONFIGURE_BREAKING_NEWS_PATH);
    }
  };
};

export default compose<any>(
  connect(mapStateToProps, mapDispatchToProps),
  WrapPage({ HeaderComponent: Header, isStoryPage: false })
)(BreakingNewsDefaults);
