/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { Image } from "api/search-media-image";
import { BreakingNews, ValidationErrors, BreakingNewsValidationFields } from "api/breaking-news";
import { BreakingNewsConfig, Features } from "api/route-data/breaking-news-route-data";
import { LinkType } from "./constants";
import { Section } from "api/route-data/route-data";
import { Tag } from "api/tag";
import { Author } from "api/author";
import * as SocialAccountAPI from "api/social-accounts";
import { LoaderState } from "behaviors/loader/state";
import { Story } from "api/story";
import { Entity } from "api/entity";

export type StoryHeroImage = Image | null;

interface Metadata {
  "story-attributes": { [key: string]: Array<string | Entity> };
}

export type StoryTemplateFields = {
  headline: string;
  subheadline: string;
  heroImage: StoryHeroImage;
  cardContent: string;
  // storyline?: any; TODO: Don't know type of this field, Needs to be fixed
  sections: Section[];
  tags: Tag[];
  // "instant-articles-video-fields": any; TODO: Don't know type of this field, Needs to be fixed
  "meta-keywords": string[];
  "meta-description": string;
  "meta-title": string;
  authors: Author[];
  summary: string;
  // "sponsored-by": any; TODO: Don't know type of this field, Needs to be fixed
  "custom-url": string;
  "canonical-url": string;
  metadata?: Metadata;
  "hero-image-alt-text"?: string;
  "home-alternative-headline"?: string;
  "social-alternative-headline"?: string;
};

export type PartialStoryTemplateFields = Partial<StoryTemplateFields>;

export type SocialAccount = Pick<SocialAccountAPI.SocialAccount, "account-id" | "account-name" | "provider"> & {
  selected: boolean;
  status?: string;
};

export interface State {
  breakingNews: BreakingNews;
  breakingNewsStory: Story | null;
  isSocialAccountsAvailable: boolean;
  socialAccounts: SocialAccount[];
  app: {
    storyTemplateFields: StoryTemplateFields;
    savedStoryTemplateFields: PartialStoryTemplateFields;
    validationErrors?: ValidationErrors<BreakingNewsValidationFields>;
    advancedStoryTemplateFields: string[];
  };
  ui: {
    isConfigLoaded: boolean;
    isFeaturesLoaded: boolean;
    loader: LoaderState;
    linkType: LinkType | null;
    showSocialAccounts: boolean;
    showAdvancedOptions: boolean;
    savingBreakingNews: boolean;
    enableBreakingNewsPublish: boolean;
  };
}

export interface PartialAppState {
  config: BreakingNewsConfig;
  features: Features;
  breakingNews: State;
}

export const INITIAL_BREAKING_NEWS: BreakingNews = {
  "story-content-id": "",
  headline: "",
  metadata: {}
};

export const STORY_TEMPLATE_FIELDS_INITIAL_STATE: StoryTemplateFields = {
  headline: "",
  subheadline: "",
  heroImage: null,
  cardContent: "",
  sections: [],
  tags: [],
  "meta-keywords": [],
  "meta-description": "",
  "meta-title": "",
  authors: [],
  summary: "",
  "custom-url": "",
  "canonical-url": ""
};

export const INITIAL_BREAKING_NEWS_STATE: State = {
  breakingNews: INITIAL_BREAKING_NEWS,
  breakingNewsStory: null,
  isSocialAccountsAvailable: false,
  socialAccounts: [],
  app: {
    storyTemplateFields: STORY_TEMPLATE_FIELDS_INITIAL_STATE,
    savedStoryTemplateFields: {},
    advancedStoryTemplateFields: [
      "hero-image-s3-key",
      "hero-image-attribution",
      "hero-image-caption",
      "headline",
      "subheadline",
      "cards",
      "summary",
      "author",
      "meta-title",
      "meta-description",
      "hero-image-alt-text",
      "image-alt-text",
      "home-alternative-hero-image-s3-key",
      "home-alternative-headline",
      "social-alternative-hero-image-s3-key",
      "social-alternative-headline"
    ]
  },
  ui: {
    isConfigLoaded: false,
    isFeaturesLoaded: false,
    loader: { loading: true, error: null },
    savingBreakingNews: false,
    linkType: null,
    showSocialAccounts: false,
    showAdvancedOptions: false,
    enableBreakingNewsPublish: false
  }
};
