/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { client } from "./client";
import { PushNotificationId, StoryId } from "./primitive-types";

export interface linkedStory {
  id: StoryId;
  headline: string;
}

export enum Platform {
  ALL = "all",
  WEB = "web",
  MOBILE = "mobile"
}

export enum PushNotificationUrlType {
  NO_LINK = "NO_LINK",
  STORY = "STORY",
  CUSTOM_URL = "CUSTOM_URL"
}

export const DEFAULT_PROVIDER = "default";

export const DEFAULT_CHANNEL = "default";

export interface TargetPlatform {
  [Platform.WEB]?: {
    provider: string;
  };
  [Platform.MOBILE]?: {
    provider: string;
  };
}

export interface Target {
  channel: string;
  platform?: TargetPlatform;
}

export interface PushNotificationListItem {
  "updated-at": number;
  id: PushNotificationId;
  message: string;
  "author-id": number;
  "linked-story-id": StoryId;
  headline: string;
  "author-name": string;
  metadata: { [key: string]: string };
}

export interface PushNotification {
  "notification-id": number;
  title: string;
  "push-notification": string;
  "publish-at": number | null;
  "story-content-id": StoryId | null;
  "target-categories": Array<Target> | null;
  "linked-story"?: linkedStory | null;
  metadata: { [key: string]: string };
}

export type UnsavedPushNotification = Pick<
  PushNotification,
  "title" | "push-notification" | "story-content-id" | "publish-at" | "target-categories" | "metadata" | "linked-story"
>;

export type AnyPushNotification = PushNotification | UnsavedPushNotification;

export const publishNewPushNotification = (
  pushNotification: Omit<UnsavedPushNotification, "target-categories">
): Promise<PushNotification> => {
  return client
    .url("/api/push-notification")
    .json({ "push-notification": pushNotification })
    .post()
    .json();
};

export const publishNewPushNotificationV1 = (
  pushNotification: Omit<UnsavedPushNotification, "targets">
): Promise<PushNotification> => {
  return client
    .url("/api/v1/push-notification")
    .json(pushNotification)
    .post()
    .json();
};

export const updatePushNotification = (
  id: PushNotificationId,
  pushNotification: Omit<UnsavedPushNotification, "target-categories">
): Promise<PushNotification> => {
  return client
    .url(`/api/push-notification/${id}`)
    .json(pushNotification)
    .patch()
    .json();
};

export const updatePushNotificationV1 = (
  id: PushNotificationId,
  pushNotification: Omit<UnsavedPushNotification, "targets">
): Promise<PushNotification> => {
  return client
    .url(`/api/v1/push-notification/${id}`)
    .json(pushNotification)
    .patch()
    .json();
};

export const loadPushNotification = (id: PushNotificationId): Promise<PushNotification> => {
  return client
    .url(`/api/v1/push-notification/${id}`)
    .get()
    .json();
};
