/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import classnames from "classnames/bind";
import { debounce, get } from "lodash";
import { PartialAppState } from "pages/story-editor/state";
import { chainCommands, setBlockType, toggleMark } from "prosemirror-commands";
import { MarkType, Node, NodeType, Schema } from "prosemirror-model";
import { EditorState, Plugin, Transaction } from "prosemirror-state";
import { EditorView } from "prosemirror-view";
import React from "react";
import { removeFormatting, toggleList } from "../../operations/commands";
import { schema } from "../../prosemirror/schema";
import ReactPluginView from "../react-plugin-view";
import Link from "./link/link";
import styles from "./toolbar.module.css";
import Caret from "components/icons/caret";
import {
  Bold,
  BulletList,
  H2,
  H3,
  H4,
  H5,
  H6,
  Italic,
  Link as LinkIcon,
  NumberedList,
  RemoveFormatting,
  StrikeThrough,
  SubScript,
  SuperScript,
  Underline
} from "components/icons/formatting-toolbar";
import { setFormattingToolbarActive, setFormattingToolbarInActive } from "pages/story-editor/action-creators";
import { findNodeClosestToPos, findParentNodeOfType } from "pages/story-editor/operations/find";
import { setTextSelection } from "pages/story-editor/operations/selection";
import { addMarkToSelection, getMarkAttrs, removeMarkFromSelection } from "pages/story-editor/prosemirror/utils";
import { connect } from "react-redux";
import { compose } from "redux";
import { ThunkDispatch } from "redux-thunk";
import Headings from "./headings/headings";
import MagicPencil from "components/icons/magic-pencil";
import AnimatedLoader from "components/icons/animated-loader";
import { paraphraseTextSelection } from "pages/story-editor/async-action-creators";

const cx = classnames.bind(styles);

function isMarkActive(type: MarkType<Schema>) {
  return function(editorState: EditorState) {
    let { from, $from, to, empty } = editorState.selection;
    if (empty) {
      return type.isInSet(editorState.storedMarks || $from.marks());
    } else {
      return editorState.doc.rangeHasMark(from, to, type);
    }
  };
}

function hasParentBlockNodeType(nodeType: NodeType, attrs = {}) {
  return function(editorState: EditorState) {
    const { $from } = editorState.selection;

    let found = false;

    for (let depth = $from.depth; depth > 0; depth--) {
      const node = $from.node(depth);

      if (node.sameMarkup(nodeType.create(attrs))) {
        found = true;
      }
    }

    return found;
  };
}

function isSelectedInlineNodeType(nodeType: NodeType, attrs = {}) {
  return function(editorState: EditorState) {
    const fragment = editorState.selection.content().content;

    if (editorState.selection.empty) {
      return false;
    }

    let foundNodes: Array<Node> = [];

    fragment.descendants((node) => {
      node.descendants((node) => {
        if (node.type.inlineContent) {
          foundNodes.push(node);
          return false;
        }
        return false;
      });
    });

    if (foundNodes.length === 0) {
      return false;
    } else {
      return foundNodes.every((foundNode) => foundNode.hasMarkup(nodeType, attrs));
    }
  };
}

const selectIconSize = (isDesktopSizeViewport: boolean) => {
  return {
    width: isDesktopSizeViewport ? "24" : "20",
    height: isDesktopSizeViewport ? "24" : "20"
  };
};

const renderSelectedHeading = (level: number) => {
  switch (level) {
    case 2:
      return <H2 />;
    case 3:
      return <H3 />;
    case 4:
      return <H4 />;
    case 5:
      return <H5 />;
    case 6:
      return <H6 />;
    default:
      return <H2 />;
  }
};

const getActiveHeading = () => (editorState: EditorState) => {
  for (let i = 2; i <= 6; i++) {
    if (isSelectedInlineNodeType(schema.nodes.heading, { level: i })(editorState)) {
      return renderSelectedHeading(i);
    }
  }
  return null;
};

interface Props {
  state: EditorState;
  view: EditorView;
  dispatch: (tr: Transaction<Schema>) => void;
}

interface State {
  showLinkInput: boolean;
  showHeadingsDropDown: boolean;
  showToolbar: boolean;
  currentlyActiveSubMenu: string | null;
}

class Toolbar extends React.Component<Props & StateProps & DispatchProps, State> {
  private formattingToolbarRef: React.RefObject<HTMLInputElement>;

  constructor(props: Props & StateProps & DispatchProps) {
    super(props);
    this.formattingToolbarRef = React.createRef();
    this.delayedRender = this.delayedRender.bind(this);
    this.delayedRender = debounce(this.delayedRender, 500);

    this.state = {
      showToolbar: false,
      showLinkInput: false,
      showHeadingsDropDown: false,
      currentlyActiveSubMenu: null
    };
  }

  delayedRender = () => {
    if (!this.state.showToolbar && !this.blockStandardToolbar()) {
      setTimeout(() => this.setState({ showToolbar: true }), 250);
    }

    if (this.state.showToolbar && this.blockStandardToolbar()) {
      this.setState({ showToolbar: false });
    }
  };

  paraphraseSelection = async (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if (this.props.isTextParaphrasingInProgress) {
      return;
    }
    const editorState = this.props.state as EditorState;
    const { from, to } = editorState.selection;
    const textToParaphrase = editorState.doc.textBetween(from, to);
    this.props.paraphraseTextSelection(textToParaphrase, { from, to });
  };

  setLink = (href?: String, isNoFollow?: boolean) => {
    let attrs: any = null;

    attrs = { href };
    if (isNoFollow) {
      attrs["rel"] = "nofollow";
    }

    this.setActiveSubMenu(null);
    const tr =
      attrs.href && attrs.href.length > 1
        ? addMarkToSelection(this.props.state, schema.marks.link, attrs)
        : removeMarkFromSelection(this.props.state, schema.marks.link);

    this.props.dispatch(setTextSelection(tr, this.props.state, tr.selection.to));
    return;
  };

  setHeading = (e: React.MouseEvent, level: number) => {
    e.preventDefault();
    e.stopPropagation();
    this.setActiveSubMenu(null);
    chainCommands(setBlockType(schema.nodes.heading, { level: level }), setBlockType(schema.nodes.paragraph))(
      this.props.state,
      this.props.dispatch
    );
  };

  setActiveSubMenu = (menuType: string | null) => {
    menuType ? this.props.setToolbarActive() : this.props.setToolbarInActive();

    this.setState((prevState) =>
      prevState.currentlyActiveSubMenu ? { currentlyActiveSubMenu: null } : { currentlyActiveSubMenu: menuType }
    );
  };

  toggleMarkNode(e: React.MouseEvent<HTMLElement>, item: MarkType) {
    e.preventDefault();
    e.stopPropagation();
    toggleMark(item)(this.props.state, this.props.dispatch);
    this.setActiveSubMenu(null);
  }

  toggleListNode(e: React.MouseEvent<HTMLElement>, item: NodeType) {
    e.preventDefault();
    e.stopPropagation();
    toggleList(schema, item)(this.props.state, this.props.dispatch);
    this.setActiveSubMenu(null);
  }

  resetToolbar = (e: React.MouseEvent<HTMLElement> | Event) => {
    if (
      this.formattingToolbarRef &&
      this.formattingToolbarRef.current &&
      e &&
      e.target &&
      this.formattingToolbarRef.current.contains(e.target as HTMLElement)
    ) {
      return;
    }

    this.setState({ currentlyActiveSubMenu: null });
  };

  isHeadingActive = (level: number) => isSelectedInlineNodeType(schema.nodes.heading, { level })(this.props.state);

  handleKeyPress = (event: KeyboardEvent) => {
    const { keyCode, metaKey, ctrlKey } = event;
    //Trigger link popup on Meta+K or Ctrl+k key combination
    if (!this.props.state.selection.empty && keyCode === 75 && (metaKey || ctrlKey)) {
      // Check if event originated in a prose mirror editor near the toolbar
      if (this.formattingToolbarRef.current) {
        const eventElement = event.target as HTMLElement;
        const eventParent = eventElement.closest("#prosemirror-text-area");
        const formattingToolbarParent = this.formattingToolbarRef.current.closest("#prosemirror-text-area");
        if (eventParent !== formattingToolbarParent) return;
      }
      this.setActiveSubMenu("link");
      this.preventEventFlowToProseMirror(event);
    }
  };

  componentDidMount() {
    document.addEventListener("mousedown", this.resetToolbar);
    document.addEventListener("keydown", this.handleKeyPress);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.resetToolbar);
    document.removeEventListener("keydown", this.handleKeyPress);
  }

  componentDidUpdate() {
    this.delayedRender();
  }

  blockStandardToolbar() {
    const selection = this.props.state.selection;
    const nodeClosestToPos = findNodeClosestToPos(this.props.state).name;

    // Had to checked anchor is zero because initial render selection is not empty
    return selection.empty || selection.anchor === 0 || nodeClosestToPos === "title_text";
  }

  isAnyListActive() {
    if (
      hasParentBlockNodeType(schema.nodes.bullet_list)(this.props.state) &&
      hasParentBlockNodeType(schema.nodes.ordered_list)(this.props.state)
    ) {
      return false;
    } else if (
      hasParentBlockNodeType(schema.nodes.bullet_list)(this.props.state) ||
      hasParentBlockNodeType(schema.nodes.ordered_list)(this.props.state)
    ) {
      return true;
    } else {
      return false;
    }
  }

  renderListIcon() {
    if (hasParentBlockNodeType(schema.nodes.bullet_list)(this.props.state)) {
      return <BulletList />;
    } else if (hasParentBlockNodeType(schema.nodes.ordered_list)(this.props.state)) {
      return <NumberedList />;
    } else {
      return <BulletList {...selectIconSize(this.props.isDesktopSizeViewport)} />;
    }
  }

  isSuperOrSubScriptActive() {
    if (
      isMarkActive(schema.marks.superscript)(this.props.state) &&
      isMarkActive(schema.marks.subscript)(this.props.state)
    ) {
      return false;
    } else if (
      isMarkActive(schema.marks.superscript)(this.props.state) ||
      isMarkActive(schema.marks.subscript)(this.props.state)
    ) {
      return true;
    } else {
      return false;
    }
  }

  renderSubScriptOrSuperScriptIcon() {
    if (
      isMarkActive(schema.marks.superscript)(this.props.state) &&
      !isMarkActive(schema.marks.subscript)(this.props.state)
    ) {
      return <SuperScript />;
    } else if (
      isMarkActive(schema.marks.subscript)(this.props.state) &&
      !isMarkActive(schema.marks.superscript)(this.props.state)
    ) {
      return <SubScript {...selectIconSize(this.props.isDesktopSizeViewport)} />;
    } else {
      return <SubScript {...selectIconSize(this.props.isDesktopSizeViewport)} />;
    }
  }

  preventEventFlowToProseMirror(e) {
    e.stopPropagation();
    e.preventDefault();
  }

  render() {
    const selection = this.props.state.selection,
      { from, to } = selection;

    let style = {},
      position = { left: 0, top: 0 },
      isTextNodeSelected = false;

    const closestStoryElementTextNode = findParentNodeOfType(this.props.state, "story_element_text");

    const isElementDisabled =
      closestStoryElementTextNode &&
      closestStoryElementTextNode.node &&
      closestStoryElementTextNode.node.attrs.isCardDisabled
        ? true
        : false;
    if (isElementDisabled) {
      return null;
    }
    interface ScreenCoordinates {
      top: number;
      bottom: number;
      left: number;
      right: number;
    }
    //Only compute the position when there is a selection
    if (!selection.empty) {
      let start: ScreenCoordinates = {
          top: 0,
          bottom: 0,
          left: 0,
          right: 0
        },
        end: ScreenCoordinates = {
          top: 0,
          bottom: 0,
          left: 0,
          right: 0
        };

      // These are in screen coordinates
      try {
        start = this.props.view.coordsAtPos(from);
        end = this.props.view.coordsAtPos(to);
      } catch (e) {
        return null;
      }

      const nodeClosestToPos = findNodeClosestToPos(this.props.state).name;
      isTextNodeSelected =
        nodeClosestToPos === "paragraph" || nodeClosestToPos === "heading" || nodeClosestToPos === "story_element_text";

      // The box in which the tooltip is positioned, to use as base
      let box = this.props.view.dom.parentNode && (this.props.view.dom.parentNode as Element).getBoundingClientRect();
      // Find a center-ish x position from the selection endpoints (when
      // crossing lines, end may be more to the left)
      let left = (end.left - start.left) / 2;
      const rootStyles = getComputedStyle(document.body);
      const navBarWidth = parseFloat(rootStyles.getPropertyValue("--navbar-width")) * 10;
      const formatToolbarWidth = parseFloat(rootStyles.getPropertyValue("--format-toolbar-width")) * 10;
      let leftPosition = start.left - navBarWidth + left - formatToolbarWidth / 2;

      if (box) {
        position = {
          left: leftPosition < 0 ? 22 : leftPosition, // 22 is just random number to make it visible on smaller screen
          top: box.bottom - start.top
        };

        style = {
          transform: `translate3d(${position.left}px, -${position.top}px, 0)`,
          position: "relative"
        };
      }
    }

    const isTextParaphrasingInProgress = this.props.isTextParaphrasingInProgress;

    return this.props.isDesktopSizeViewport || this.props.isMobileStylingToolbarEnabled ? (
      <div
        className={styles["formatting-toolbar-wrapper"]}
        ref={this.formattingToolbarRef}
        style={style}
        data-test-id="format-toolbar-wrapper">
        <ul
          className={cx(
            "format-toolbar",
            { "format-toolbar--active": this.state.currentlyActiveSubMenu },
            { "format-toolbar--focused": !this.blockStandardToolbar() && this.state.showToolbar }
          )}
          data-test-id="format-toolbar">
          <li
            data-test-id="format-toolbar-item-bold"
            key="format-toolbar-bold"
            onMouseDown={(e) => this.toggleMarkNode(e, schema.marks.strong)}
            className={cx("format-toolbar-item", { "is-active": isMarkActive(schema.marks.strong)(this.props.state) })}>
            <Bold {...selectIconSize(this.props.isDesktopSizeViewport)} />
          </li>
          <li
            data-test-id="format-toolbar-item-italic"
            key="format-toolbar-italic"
            onMouseDown={(e) => this.toggleMarkNode(e, schema.marks.em)}
            className={cx("format-toolbar-item", { "is-active": isMarkActive(schema.marks.em)(this.props.state) })}>
            <Italic {...selectIconSize(this.props.isDesktopSizeViewport)} />
          </li>

          <li
            data-test-id="format-toolbar-item-underline"
            key="format-toolbar-underline"
            onMouseDown={(e) => this.toggleMarkNode(e, schema.marks.underline)}
            className={cx("format-toolbar-item", {
              "is-active": isMarkActive(schema.marks.underline)(this.props.state)
            })}>
            <Underline {...selectIconSize(this.props.isDesktopSizeViewport)} />
          </li>

          {this.props.isParaphraseTextEnabled && (
            <li
              key="format-toolbar-paraphrase"
              onMouseDown={(e) => this.paraphraseSelection(e)}
              className={cx("format-toolbar-item", {
                "is-disabled": isTextParaphrasingInProgress
              })}>
              <div className={cx("paraphrase-text")}>
                <span className={cx({ "paraphrase-text__icon--loading": isTextParaphrasingInProgress })}>
                  <MagicPencil {...selectIconSize(this.props.isDesktopSizeViewport)} />
                </span>
                {isTextParaphrasingInProgress && (
                  <span className={cx("paraphrase-text__loader")}>
                    <AnimatedLoader />
                  </span>
                )}
              </div>
            </li>
          )}

          <li
            data-test-id="format-toolbar-item-strikethrough"
            key="format-toolbar-strikethrough"
            onMouseDown={(e) => this.toggleMarkNode(e, schema.marks.strikethrough)}
            className={cx("format-toolbar-item", {
              "is-active": isMarkActive(schema.marks.strikethrough)(this.props.state)
            })}>
            <StrikeThrough {...selectIconSize(this.props.isDesktopSizeViewport)} />
          </li>

          <li
            data-test-id="format-toolbar-item-superscript-and-subscript"
            key="format-toolbar-superscript-and-subscript"
            onMouseDown={(e) => {
              this.resetToolbar(e);
              this.state.currentlyActiveSubMenu !== "superAndSubScript"
                ? this.setActiveSubMenu("superAndSubScript")
                : this.setActiveSubMenu(null);
            }}
            className={cx("format-toolbar-item", {
              "is-active": this.isSuperOrSubScriptActive()
            })}>
            {this.renderSubScriptOrSuperScriptIcon()}
            <Caret variant="down" width={16} height={16} />
            {this.state.currentlyActiveSubMenu === "superAndSubScript" && (
              <ul
                className={cx("format-toolbar-submenu-wrapper", "format-toolbar-super-and-sub-script-submenu")}
                data-test-id="format-toolbar-super-and-sub-script-submenu">
                <li
                  className={styles["format-toolbar-submenu-item"]}
                  onMouseDown={(e) => this.toggleMarkNode(e, schema.marks.superscript)}
                  data-test-id="format-toolbar-superscript">
                  <SuperScript {...selectIconSize(this.props.isDesktopSizeViewport)} />
                </li>
                <li
                  className={styles["format-toolbar-submenu-item"]}
                  onMouseDown={(e) => this.toggleMarkNode(e, schema.marks.subscript)}
                  data-test-id="format-toolbar-subscript">
                  <SubScript {...selectIconSize(this.props.isDesktopSizeViewport)} />
                </li>
              </ul>
            )}
          </li>
          {isTextNodeSelected && (
            <li
              data-test-id="format-toolbar-list"
              key="format-toolbar-list"
              onMouseDown={(e) => {
                this.resetToolbar(e);
                this.state.currentlyActiveSubMenu !== "lists"
                  ? this.setActiveSubMenu("lists")
                  : this.setActiveSubMenu(null);
              }}
              className={cx("format-toolbar-item", {
                "is-active": this.isAnyListActive()
              })}>
              {this.renderListIcon()}
              <Caret variant="down" width={16} height={16} />
              {this.state.currentlyActiveSubMenu === "lists" && (
                <ul
                  className={cx("format-toolbar-submenu-wrapper", "format-toolbar-lists-submenu")}
                  data-test-id="format-toolbar-lists-submenu">
                  <li
                    data-test-id="format-toolbar-bullet-list"
                    className={styles["format-toolbar-submenu-item"]}
                    onMouseDown={(e) => this.toggleListNode(e, schema.nodes.bullet_list)}>
                    <BulletList {...selectIconSize(this.props.isDesktopSizeViewport)} />
                  </li>
                  <li
                    data-test-id="format-toolbar-numbered-list"
                    className={styles["format-toolbar-submenu-item"]}
                    onMouseDown={(e) => this.toggleListNode(e, schema.nodes.ordered_list)}>
                    <NumberedList {...selectIconSize(this.props.isDesktopSizeViewport)} />
                  </li>
                </ul>
              )}
            </li>
          )}
          <li
            data-test-id="format-toolbar-link"
            key="format-toolbar-link"
            onMouseDown={(e) => {
              this.preventEventFlowToProseMirror(e);
              this.state.currentlyActiveSubMenu !== "link"
                ? this.setActiveSubMenu("link")
                : this.setActiveSubMenu(null);
            }}
            className={cx("format-toolbar-item", {
              "is-active": isMarkActive(schema.marks.link)(this.props.state)
            })}>
            <LinkIcon {...selectIconSize(this.props.isDesktopSizeViewport)} />
          </li>
          {isTextNodeSelected && (
            <li
              data-test-id="format-toolbar-heading"
              key="format-toolbar-heading"
              onMouseDown={(e) =>
                this.state.currentlyActiveSubMenu !== "headings"
                  ? this.setActiveSubMenu("headings")
                  : this.setActiveSubMenu(null)
              }
              className={cx("format-toolbar-item", {
                "is-active": getActiveHeading()(this.props.state)
              })}>
              {getActiveHeading()(this.props.state) ? (
                getActiveHeading()(this.props.state)
              ) : (
                <H2 {...selectIconSize(this.props.isDesktopSizeViewport)} />
              )}
              <Caret variant="down" width={16} height={16} />
              {this.state.currentlyActiveSubMenu === "headings" && <Headings setHeading={this.setHeading} />}
            </li>
          )}
          <li
            data-test-id="format-toolbar-remove-formatting"
            key="format-toolbar-remove-formatting"
            onMouseDown={(e) => removeFormatting(schema)(this.props.state, this.props.dispatch)}
            className={styles["format-toolbar-item"]}>
            <RemoveFormatting {...selectIconSize(this.props.isDesktopSizeViewport)} />
          </li>
        </ul>
        {this.state.currentlyActiveSubMenu === "link" && (
          <Link setLink={this.setLink} attrs={getMarkAttrs(this.props.state, "link")} />
        )}
      </div>
    ) : null;
  }
}

export { Toolbar };

interface StateProps {
  isMobileStylingToolbarEnabled: boolean;
  isDesktopSizeViewport: boolean;
  isParaphraseTextEnabled: boolean;
  isStorySaving: boolean;
  isTextParaphrasingInProgress: boolean;
}

interface DispatchProps {
  setToolbarActive: () => void;
  setToolbarInActive: () => void;
  paraphraseTextSelection: (text: string, selection: { from: number; to: number }) => void;
}

const mapStateToProps = (state: PartialAppState): StateProps => {
  return {
    isMobileStylingToolbarEnabled: state.features.isMobileStylingToolbarEnabled,
    isDesktopSizeViewport: state.viewport.isDesktopSizeViewport,
    isParaphraseTextEnabled: get(state.config, ["ai-content-generation", "story", "paraphrase-text"], false),
    isStorySaving: get(state, ["storyEditor", "ui", "isStorySaving"], false),
    isTextParaphrasingInProgress: get(state, ["storyEditor", "ui", "isTextParaphrasingInProgress"], false)
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, any>): DispatchProps => {
  return {
    setToolbarActive: () => dispatch(setFormattingToolbarActive()),
    setToolbarInActive: () => dispatch(setFormattingToolbarInActive()),
    paraphraseTextSelection: (text, selection) => dispatch(paraphraseTextSelection(text, selection))
  };
};

const WrappedToolbar = compose(connect(mapStateToProps, mapDispatchToProps))(Toolbar);

export function toolbar() {
  return new Plugin({
    view(editorView) {
      return new ReactPluginView(editorView, WrappedToolbar, {
        domAttributes: { class: "format-toolbar-container" }
      });
    }
  });
}
