/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import produce from "immer";
import INITIAL_STATE, { INITIAL_HERO_IMAGE_STATE } from "./initial-state";
import { actions, DeleteHeroImage, SetStoryTransitionInProgress, SetTextParaphrasingStatus } from "../actions";
import { newCard } from "../data/card";
import { appendStoryElementToCard } from "../data/default-story-element";
import appendTemplateCards from "../operations/card/append-template-cards";
import appendCard from "../operations/card/append";
import deleteCard from "../operations/card/delete";
import moveCard, { Direction } from "../operations/card/move";
import moveStoryElementUpInCompositeElements from "../operations/composite-story-elements/move-up";
import moveStoryElementDownInCompositeElements from "../operations/composite-story-elements/move-down";

import { pinCard, addKeyEvent } from "../operations/card/live-blog";
import { deleteStoryElement, deleteCompositeStoryElement } from "../operations/story-elements/delete";
import addStoryElement, { StoryElementDirection, splitStoryElement } from "../operations/story-elements/add";
import moveStoryElementDown from "../operations/story-elements/move/down";
import moveStoryElementUp from "../operations/story-elements/move/up";
import { makeEditorState } from "../prosemirror/prosemirror";
import { addIfNotExists } from "utils/array.utils";
import mergeStory, { mergeCardsLoaded } from "../merge-story";
import { updateStory, updateStoryAndEditorState } from "../reducer";
import { StoryElementId, CardId, PolltypeId } from "api/primitive-types";
import {
  AnyStory,
  Story,
  StoryElement,
  StoryElements,
  EditorConfig,
  WorkflowTransitions,
  CompositeStoryElement,
  ChildStoryElement,
  Card,
  StoryEntity
} from "api/story";
import { EditorState } from "prosemirror-state";
import { removeKeyFromObject } from "utils";
import { Image, ImageOrNewImage } from "api/search-media-image";
import { Poll } from "api/polls";
import { Entity } from "api/entity";
import { getNewChunkedEditorState, getNextCardInStory, getNextElementInStory } from "../utils";
import { getReduxActionLog } from "utils";
import { ItsmanWindow } from "containers/page/page";

const w = window as ItsmanWindow;

export type ActionType =
  | { type: actions.UPDATE_STORY_ELEMENT; payload: { id: StoryElementId; changes: {} } }
  | { type: actions.UPDATE_IMAGE_ELEMENT; payload: { id: StoryElementId; changes: {} } }
  | {
      type: actions.STORY_EDITOR_ADD_STORY_ELEMENT;
      payload: {
        currentStoryElement: StoryElement | CompositeStoryElement;
        newStoryElement: StoryElement | CompositeStoryElement | ChildStoryElement;
        addNewElementAt: StoryElementDirection;
      };
    }
  | {
      type: actions.STORY_EDITOR_SPLIT_STORY_ELEMENT;
      payload: {
        currentStoryElement: StoryElement | CompositeStoryElement;
        newStoryElement: StoryElement | CompositeStoryElement | ChildStoryElement;
        newTextElement: StoryElement | CompositeStoryElement | ChildStoryElement;
        addNewElementAt: number;
      };
    }
  | {
      type: actions.STORY_EDITOR_DELETE_STORY_ELEMENT;
      payload: { storyElement: StoryElement | CompositeStoryElement | ChildStoryElement };
    }
  | { type: actions.STORY_EDITOR_MOVE_STORY_ELEMENT; payload: { storyElement: StoryElement; direction: string } }
  | { type: actions.DUMMY_PASTE }
  | { type: actions.DUMMY_CUT }
  | { type: actions.DUMMY_BACKSPACE }
  | { type: actions.DUMMY_DELETE }
  | { type: actions.DUMMY_UNDO }
  | { type: actions.DUMMY_REDO }
  | { type: actions.ADD_NEW_CARD; payload: { storyElement: StoryElement; direction: string } }
  | { type: actions.ADD_TEMPLATE_CARDS }
  | { type: actions.DELETE_DEFAULT_CARD }
  | { type: actions.DELETE_STORY_EDITOR_CARD; payload: { cardId: CardId } }
  | { type: actions.MOVE_STORY_EDITOR_CARD_UP; payload: { cardId: CardId } }
  | { type: actions.MOVE_STORY_EDITOR_CARD_DOWN; payload: { cardId: CardId } }
  | { type: actions.STORY_EDITOR_PIN_CARD; payload: { cardId: CardId } }
  | { type: actions.STORY_EDITOR_ADD_KEY_EVENT; payload: { cardId: CardId } }
  | { type: actions.UPDATE_OEMBED_URL; payload: { id: StoryElementId; url: string } }
  | { type: actions.SET_OEMBED_URL; payload: { id: StoryElementId; changes: { provider: string } } }
  | { type: actions.SET_STORY_ELEMENT_ERROR; payload: { id: StoryElementId; error: string | {} } }
  | { type: actions.REMOVE_STORY_ELEMENT_ERROR; payload: { id: StoryElementId } }
  | { type: actions.TOGGLE_STORY_ELEMENT_LOADING; payload: { id: StoryElementId } }
  | { type: actions.TOGGLE_ALTERNATIVE_TITLE }
  | { type: actions.UPDATE_ALTERNATIVE; payload: { key: string; value: string | ImageOrNewImage; location: string } }
  | { type: actions.SET_IS_STORY_MODIFIED_STATE; payload: { isStoryModified: boolean } }
  | { type: actions.UPDATE_HERO_IMAGE_DATA; payload: { image: Image } }
  | DeleteHeroImage
  | { type: actions.SET_SELECTED_HERO_IMAGE; payload: ImageOrNewImage }
  | { type: actions.STORY_EDITOR_IMAGE_UPLOADING; payload: { status: Boolean } }
  | {
      type: actions.UPDATE_STORY_STATUS;
      payload: { status: Boolean; workflowTransitions: WorkflowTransitions; taskId: string };
    }
  | { type: actions.SET_WORKFLOW_TRANSITIONS; payload: { workflowTransitions: WorkflowTransitions } }
  | { type: actions.SET_STORY; payload: { story: Story; workflowTransitions: WorkflowTransitions } }
  | { type: actions.TOGGLE_CARD_SHARE; payload: { cardId: CardId; value: Boolean } }
  | {
      type: actions.CARD_SHARE_UPDATE;
      payload: { cardId: CardId; key: string; value: Boolean | string | Image };
    }
  | {
      type: actions.CARD_ATTRIBUTES_UPDATE;
      payload: { cardId: CardId; key: string; value: Array<string | StoryEntity> };
    }
  | { type: actions.CARD_SHARE_IMAGE_DELETE; payload: { cardId: CardId } }
  | { type: actions.UPDATE_STORY_LOCK; payload: { value: Boolean } }
  | {
      type: actions.SET_OPINION_POLL;
      payload: { storyElementId: StoryElementId; poll: Poll };
    }
  | { type: actions.UPDATE_OPINION_POLL; payload: { storyElementId: StoryElementId; key: string; value: any } }
  | {
      type: actions.IMPORT_CARD;
      payload: { card: Card; storyElements: Array<StoryElement | CompositeStoryElement | ChildStoryElement> };
    }
  | {
      type: actions.SET_OPINION_POLL_ID;
      payload: { storyElementId: StoryElementId; pollId: PolltypeId };
    }
  | {
      type: actions.UPDATE_OPINION_POLL_IMAGE_UPLOAD_STATUS;
      payload: { uploading: boolean };
    }
  | {
      type: actions.SET_IMAGES_FOR_IMAGE_GALLERY_ELEMENT;
      payload: { storyElementId: StoryElementId; images: Image[] };
    }
  | {
      type: actions.STORY_EDITOR_MOVE_STORY_ELEMENT_UP;
      payload: { imageStoryElement: ChildStoryElement; parentStoryElement: StoryElement };
    }
  | {
      type: actions.STORY_EDITOR_MOVE_STORY_ELEMENT_DOWN;
      payload: { imageStoryElement: ChildStoryElement; parentStoryElement: StoryElement };
    }
  | {
      type: actions.SET_OPINION_POLL_INIT;
      payload: {
        storyElementId: StoryElementId;
      };
    }
  | {
      type: actions.SET_OPINION_POLL_SUCCESS;
      payload: {
        storyElementId: StoryElementId;
      };
    }
  | { type: actions.UPDATE_OPINION_POLL; payload: { storyElementId: StoryElementId; key: string; value: any } }
  | { type: actions.SET_FORMATTING_TOOLBAR_ACTIVE }
  | { type: actions.SET_FORMATTING_TOOLBAR_INACTIVE }
  | {
      type: actions.UPDATE_STORY_ELEMENT_ENTITIES;
      payload: {
        storyElementId: StoryElementId;
        newEntityAttribution: Entity | null;
      };
    }
  | SetStoryTransitionInProgress
  | SetTextParaphrasingStatus;

export const getCardId = (elementId: StoryElementId, elements: StoryElements) =>
  elements[elementId] && elements[elementId]["card-id"];

export const updateUpdatedCards = (
  storyElement: StoryElement | CompositeStoryElement | ChildStoryElement,
  story: AnyStory
) => {
  const cardId =
    (storyElement && storyElement["card-id"]) ||
    getCardId(storyElement && storyElement["composite-element-id"], story["story-elements"]);
  return cardId ? addIfNotExists(story["updated-cards"], cardId) : story["updated-cards"];
};

export const updateOpinionPollChanges = (storyElement: { poll: Poll }, key: string, value: any) => {
  const updatedPoll = { ...storyElement.poll, [key]: value };

  return {
    ...storyElement,
    poll: updatedPoll
  };
};

const updateEntities = (entities: any, path: string, newEntities: Array<StoryEntity>) => {
  let currentEntities = entities[path] || [];
  newEntities.map((entity) => {
    if (!currentEntities.some((storyEntityId: Number) => storyEntityId === entity.id)) currentEntities.push(entity.id);
    return null;
  });
  return { ...entities, [path]: currentEntities };
};

export default function storyContentReducer(state = INITIAL_STATE, action: ActionType) {
  try {
    switch (action.type) {
      case actions.UPDATE_STORY_ELEMENT: {
        const changes = {
          "story-elements": {
            ...state.story["story-elements"],
            [action.payload.id]: { ...state.story["story-elements"][action.payload.id], ...action.payload.changes }
          },
          "updated-cards": updateUpdatedCards(state.story["story-elements"][action.payload.id], state.story)
        };
        return updateStory(state, changes, {});
      }

      case actions.UPDATE_IMAGE_ELEMENT: {
        const changes = {
          "story-elements": {
            ...state.story["story-elements"],
            [action.payload.id]: {
              ...state.story["story-elements"][action.payload.id],
              image: {
                ...((state.story["story-elements"][action.payload.id] &&
                  state.story["story-elements"][action.payload.id].image) ||
                  {}),
                ...action.payload.changes
              }
            }
          },
          "updated-cards": updateUpdatedCards(state.story["story-elements"][action.payload.id], state.story)
        };

        return updateStory(state, changes, {});
      }

      case actions.STORY_EDITOR_ADD_STORY_ELEMENT: {
        const { story, editorState } = addStoryElement(
          state.story,
          state.editorState as EditorState,
          action.payload.currentStoryElement,
          action.payload.newStoryElement,
          action.payload.addNewElementAt
        );

        return updateStoryAndEditorState(state, story, editorState, {
          recentlyAddedElementId: action.payload.newStoryElement.id
        });
      }

      case actions.STORY_EDITOR_SPLIT_STORY_ELEMENT: {
        const { story, editorState } = splitStoryElement(
          state.story,
          state.editorState as EditorState,
          action.payload.currentStoryElement,
          action.payload.newStoryElement,
          action.payload.newTextElement,
          action.payload.addNewElementAt
        );

        return updateStoryAndEditorState(state, story, editorState, {});
      }

      case actions.STORY_EDITOR_DELETE_STORY_ELEMENT: {
        const { story, editorState } =
          action.payload.storyElement.type === "composite"
            ? deleteCompositeStoryElement(
                state.story,
                state.editorState as EditorState,
                action.payload.storyElement as CompositeStoryElement
              )
            : deleteStoryElement(state.story, state.editorState as EditorState, action.payload.storyElement);
        return updateStoryAndEditorState(state, story, editorState, {});
      }

      case actions.STORY_EDITOR_MOVE_STORY_ELEMENT: {
        const { direction, storyElement } = action.payload;
        const { story, editorState } =
          direction === "DOWN"
            ? moveStoryElementDown(state.story, state.editorState as EditorState, storyElement)
            : moveStoryElementUp(state.story, state.editorState as EditorState, storyElement);

        const { cardsToLoad, currentCardLoading } = state.ui;

        let newCurrentCard = currentCardLoading;
        let newCardsToLoad = cardsToLoad;

        if (
          direction === "DOWN" &&
          currentCardLoading &&
          currentCardLoading.elements.includes(getNextElementInStory(state.story as Story, storyElement))
        ) {
          const elementsToLoad = currentCardLoading.elements.filter((elem: string) => elem !== storyElement.id);
          newCurrentCard = { card: currentCardLoading.card, elements: elementsToLoad };

          if (elementsToLoad.length === 0) {
            newCurrentCard = null;
            newCardsToLoad = [...cardsToLoad, currentCardLoading.card];
          }
        }

        const ui = {
          currentCardLoading: newCurrentCard,
          cardsToLoad: newCardsToLoad
        };

        return updateStoryAndEditorState(state, story, editorState, ui);
      }

      case actions.DUMMY_PASTE:
      case actions.DUMMY_CUT:
      case actions.DUMMY_BACKSPACE:
      case actions.DUMMY_DELETE:
      case actions.DUMMY_UNDO:
      case actions.DUMMY_REDO: {
        return {
          ...state
        };
      }

      case actions.ADD_NEW_CARD: {
        const { storyElements, card } = appendStoryElementToCard(
            newCard(),
            state.editorConfig as EditorConfig,
            state.story["story-template"]
          ),
          { story, editorState } = appendCard(
            state.story,
            state.editorState as EditorState,
            card,
            storyElements as StoryElement[]
          );
        const cardsLoaded = [...state.ui.cardsLoaded, card["content-id"]];
        const uiOpts =
          story["story-template"] === "live-blog"
            ? { cardsLoaded, numberOfCardsShown: state.ui.numberOfCardsShown + 1, recentlyAddedCardId: card.id }
            : { cardsLoaded, recentlyAddedCardId: card.id };
        return updateStoryAndEditorState(state, story, editorState, uiOpts);
      }

      case actions.ADD_TEMPLATE_CARDS: {
        const { story, editorState, newCards } = appendTemplateCards(
          state.story,
          state.editorState as EditorState,
          state.editorConfig as EditorConfig
        );
        const uiOpts = { cardsLoaded: [...state.ui.cardsLoaded, ...newCards.map((card) => card["content-id"])] };
        return {
          ...state,
          story,
          editorState,
          initialEditorState: editorState,
          ui: { ...state.ui, ...uiOpts }
        };
      }

      case actions.DELETE_DEFAULT_CARD: {
        const keys = Object.keys(state.story.cards);
        const cardId = keys[0];
        const card = { id: cardId, "content-id": cardId, tree: [] };
        const { story, editorState } = deleteCard(state.story, state.editorState as EditorState, card);
        let { cardsLoaded, cardsToLoad } = state.ui;

        let uiOpts = {};
        const deletedCardLoaded = cardsLoaded.includes(cardId);
        if (deletedCardLoaded) {
          cardsLoaded = cardsLoaded.filter((card) => card !== cardId);
          uiOpts = { cardsToLoad };
        }
        return updateStoryAndEditorState(state, story, editorState, uiOpts);
      }

      case actions.DELETE_STORY_EDITOR_CARD: {
        const cardId = action.payload.cardId;
        const card = { id: cardId, "content-id": cardId, tree: [] };
        const { story, editorState } = deleteCard(state.story, state.editorState as EditorState, card);
        let { numberOfCardsShown, cardsLoaded, currentCardLoading, cardsToLoad } = state.ui;

        let uiOpts = {};
        const deletedCardLoaded = cardsLoaded.includes(cardId);
        if (deletedCardLoaded) {
          cardsLoaded = cardsLoaded.filter((card) => card !== cardId);
        }
        if (story["story-template"] === "live-blog" && deletedCardLoaded) {
          uiOpts = { cardsLoaded, numberOfCardsShown: numberOfCardsShown - 1 };
        } else if (currentCardLoading && currentCardLoading.card === cardId) {
          uiOpts = { currentCardLoading: null };
        } else if (deletedCardLoaded) {
          uiOpts = { cardsToLoad };
        }
        return updateStoryAndEditorState(state, story, editorState, uiOpts);
      }

      case actions.MOVE_STORY_EDITOR_CARD_UP: {
        const { cardId } = action.payload;
        const { cardsLoaded, currentCardLoading } = state.ui;

        let uiOpts = { cardsLoaded, currentCardLoading };

        if (currentCardLoading && currentCardLoading.card === cardId) {
          uiOpts = { cardsLoaded: [...cardsLoaded, currentCardLoading.card], currentCardLoading: null };
        }

        const card = { id: action.payload.cardId, "content-id": cardId, tree: [] };
        let { story, editorState } = moveCard(
          state.story,
          state.editorState as EditorState,
          card,
          Direction.UP,
          uiOpts
        );

        return updateStoryAndEditorState(state, story, editorState, uiOpts);
      }

      case actions.MOVE_STORY_EDITOR_CARD_DOWN: {
        const { cardId } = action.payload;
        let { cardsLoaded, currentCardLoading, cardsToLoad, numberOfCardsShown } = state.ui;

        let uiOpts = { cardsLoaded, currentCardLoading, cardsToLoad, numberOfCardsShown };
        const movedCardId = getNextCardInStory(state.story as Story, cardId);
        cardsLoaded = [...cardsLoaded, movedCardId];
        numberOfCardsShown = cardsLoaded.length;
        if (currentCardLoading && currentCardLoading.card === movedCardId) {
          uiOpts = {
            cardsLoaded,
            currentCardLoading: null,
            cardsToLoad,
            numberOfCardsShown
          };
        } else if (cardsToLoad.includes(movedCardId)) {
          uiOpts = {
            cardsLoaded,
            currentCardLoading,
            cardsToLoad: cardsToLoad.filter((cardId) => cardId !== movedCardId),
            numberOfCardsShown
          };
        }
        const card = { id: cardId, "content-id": cardId, tree: [] };
        const { story, editorState } = moveCard(
          state.story,
          state.editorState as EditorState,
          card,
          Direction.DOWN,
          uiOpts
        );
        return updateStoryAndEditorState(state, story, editorState, uiOpts);
      }

      case actions.STORY_EDITOR_PIN_CARD: {
        const { cardId } = action.payload;

        const story = pinCard(state.story, cardId);

        return updateStoryAndEditorState(state, story, state.editorState, {});
      }

      case actions.STORY_EDITOR_ADD_KEY_EVENT: {
        const { cardId } = action.payload;

        const story = addKeyEvent(state.story, cardId);

        return updateStoryAndEditorState(state, story, state.editorState, {});
      }

      case actions.UPDATE_OEMBED_URL: {
        return {
          ...state,
          ui: {
            ...state.ui,
            storyElements: {
              ...state.ui.storyElements,
              [action.payload.id]: { ...state.ui.storyElements[action.payload.id], url: action.payload.url }
            }
          }
        };
      }

      case actions.SET_OEMBED_URL: {
        const reset = {
          metadata: {},
          url: null,
          type: action.payload.changes.provider,
          "embed-url": null,
          subtype: null,
          "embed-js": null
        };
        const cleanChanges = { ...reset, ...action.payload.changes };

        const changes = {
          "story-elements": {
            ...state.story["story-elements"],
            [action.payload.id]: { ...state.story["story-elements"][action.payload.id], ...cleanChanges }
          },
          "updated-cards": updateUpdatedCards(state.story["story-elements"][action.payload.id], state.story)
        };
        const updatedState = updateStory(state, changes, {});

        return {
          ...updatedState,
          ui: {
            ...updatedState.ui,
            storyElements: {
              ...state.ui.storyElements,
              [action.payload.id]: state.ui.storyElements[action.payload.id]
                ? { ...removeKeyFromObject("url", state.ui.storyElements[action.payload.id]), loading: false }
                : { ...state.ui.storyElements[action.payload.id], loading: false }
            }
          }
        };
      }

      case actions.SET_STORY_ELEMENT_ERROR: {
        return {
          ...state,
          ui: {
            ...state.ui,
            storyElements: {
              ...state.ui.storyElements,
              [action.payload.id]: { ...state.ui.storyElements[action.payload.id], error: action.payload.error }
            }
          }
        };
      }

      case actions.REMOVE_STORY_ELEMENT_ERROR: {
        return {
          ...state,
          ui: {
            ...state.ui,
            storyElements: {
              ...state.ui.storyElements,
              [action.payload.id]: removeKeyFromObject("error", state.ui.storyElements[action.payload.id])
            }
          }
        };
      }

      case actions.TOGGLE_STORY_ELEMENT_LOADING: {
        return {
          ...state,
          ui: {
            ...state.ui,
            storyElements: {
              ...state.ui.storyElements,
              [action.payload.id]: {
                ...state.ui.storyElements[action.payload.id],
                loading: state.ui.storyElements[action.payload.id]
                  ? !state.ui.storyElements[action.payload.id].loading
                  : true
              }
            }
          }
        };
      }

      case actions.TOGGLE_ALTERNATIVE_TITLE: {
        return {
          ...state,
          ui: {
            ...state.ui,
            showAlternateTitle: !state.ui.showAlternateTitle
          }
        };
      }

      case actions.UPDATE_ALTERNATIVE: {
        const alternative = state.story.alternative;
        const defaultValue =
          (alternative[action.payload.location] && alternative[action.payload.location].default) || {};
        const changes = {
          alternative: {
            ...state.story.alternative,
            [action.payload.location]: {
              ...state.story.alternative[action.payload.location],
              default: {
                ...defaultValue,
                [action.payload.key]: action.payload.value
              }
            }
          }
        };
        return updateStory(state, changes, {});
      }

      case actions.SET_IS_STORY_MODIFIED_STATE: {
        return {
          ...state,
          ui: {
            ...state.ui,
            isStoryModified: action.payload.isStoryModified
          }
        };
      }

      case actions.UPDATE_HERO_IMAGE_DATA: {
        const changes = {
          "hero-image": action.payload.image
        };

        return updateStory(state, changes, {});
      }

      case actions.DELETE_HERO_IMAGE: {
        const changes = {
          "hero-image": INITIAL_HERO_IMAGE_STATE
        };

        return updateStory(state, changes, {});
      }

      case actions.SET_SELECTED_HERO_IMAGE: {
        const changes = {
          "hero-image": action.payload
        };

        return updateStory(state, changes, {});
      }

      case actions.STORY_EDITOR_IMAGE_UPLOADING: {
        return {
          ...state,
          ui: {
            ...state.ui,
            imageUploading: action.payload.status
          }
        };
      }

      case actions.UPDATE_STORY_STATUS: {
        return {
          ...state,
          story: {
            ...state.story,
            status: action.payload.status,
            "task-id": action.payload.taskId
          },
          ui: {
            ...state.ui,
            workflowTransitions: action.payload.workflowTransitions,
            isStatusTransitionInProgress: false,
            lastUpdatedTime: 0,
            errors: {
              inspector: {},
              editor: {}
            }
          }
        };
      }

      case actions.SET_STORY_TRANSITION_STATUS: {
        return {
          ...state,
          ui: {
            ...state.ui,
            isStatusTransitionInProgress: action.payload.isTransitionInProgress
          }
        };
      }

      case actions.SET_WORKFLOW_TRANSITIONS: {
        return {
          ...state,
          ui: {
            ...state.ui,
            workflowTransitions: action.payload.workflowTransitions
          }
        };
      }

      case actions.SET_STORY: {
        const serverStory = action.payload.story;
        const latestStory = mergeStory(state.story, serverStory);
        const selection = state.editorState ? state.editorState.selection : null;

        let editorState;
        const cardsLoaded = mergeCardsLoaded(state.ui.cardsLoaded, state.story, serverStory);

        if (latestStory["story-template"] === "live-blog") {
          const numberOfCardsShown = state.ui.numberOfCardsShown;

          const tree = latestStory.tree.slice(0, numberOfCardsShown);
          editorState = makeEditorState(
            {
              ...latestStory,
              tree
            },
            {},
            selection
          );
        } else {
          editorState = getNewChunkedEditorState(latestStory, cardsLoaded, state.ui.currentCardLoading, selection);
        }

        return {
          ...state,
          story: latestStory,
          editorState,
          initialEditorState: editorState,
          ui: {
            ...state.ui,
            cardsLoaded,
            workflowTransitions: action.payload.workflowTransitions
          }
        };
      }

      case actions.TOGGLE_CARD_SHARE: {
        const changes = {
          cards: {
            ...state.story.cards,
            [action.payload.cardId]: {
              ...state.story.cards[action.payload.cardId],
              metadata: {
                ...state.story.cards[action.payload.cardId].metadata,
                "social-share": {
                  shareable: action.payload.value,
                  title: state.story.headline
                }
              }
            }
          }
        };
        return updateStory(state, changes, {});
      }

      case actions.CARD_SHARE_UPDATE: {
        return produce(state, (draft) => {
          const metadata = draft.story.cards[action.payload.cardId].metadata;
          if (metadata && metadata["social-share"]) {
            metadata["social-share"][action.payload.key] = action.payload.value;
            draft.ui.isStoryModified = true;
            draft.story["updated-cards"] = addIfNotExists(state.story["updated-cards"], action.payload.cardId);
          }
        });
      }

      case actions.UPDATE_STORY_ELEMENT_ENTITIES: {
        let entityPath = `storyElement_${action.payload.storyElementId}`;

        if (action.payload.newEntityAttribution) {
          let changes = {
            entities: { ...state.story.entities, [entityPath]: [action.payload.newEntityAttribution.id] }
          };
          return updateStory(state, changes, {});
        } else {
          let changes = { entities: removeKeyFromObject(entityPath, state.story.entities) };
          return updateStory(state, changes, {});
        }
      }

      case actions.CARD_ATTRIBUTES_UPDATE: {
        return produce(state, (draft) => {
          const { cardId, key, value } = action.payload;
          let entityPath = "cardAttributes_" + cardId;

          const metadata = draft.story.cards[cardId].metadata;
          if (metadata && !metadata.attributes) {
            metadata["attributes"] = {};
          }
          if (metadata && !metadata["social-share"]) {
            metadata["social-share"] = {};
          }
          if (metadata && metadata.attributes) {
            metadata.attributes[key] = value;
            draft.ui.isStoryModified = true;
            draft.story["updated-cards"] = addIfNotExists(state.story["updated-cards"], cardId);

            const entityId = value && Array.isArray(value) && value.length > 0 ? value[0]["id"] : null;
            if (entityId) {
              draft.story.entities = updateEntities(draft.story.entities, entityPath, value as Array<StoryEntity>);
            }
          }
        });
      }

      case actions.CARD_SHARE_IMAGE_DELETE: {
        return produce(state, (draft) => {
          const metadata = draft.story.cards[action.payload.cardId].metadata;
          if (metadata && metadata["social-share"]) {
            metadata["social-share"].image = null;
            draft.ui.isStoryModified = true;
            draft.story["updated-cards"] = addIfNotExists(state.story["updated-cards"], action.payload.cardId);
          }
        });
      }

      case actions.UPDATE_STORY_LOCK: {
        return {
          ...state,
          ui: {
            ...state.ui,
            isStoryLocked: action.payload.value
          }
        };
      }
      case actions.SET_OPINION_POLL_ID: {
        const { storyElementId, pollId } = action.payload;
        const changes = {
          "story-elements": {
            ...state.story["story-elements"],
            [storyElementId]: {
              ...state.story["story-elements"][storyElementId],
              "polltype-id": pollId
            }
          }
        };
        return updateStory(state, changes, {});
      }

      case actions.SET_OPINION_POLL: {
        const { storyElementId, poll } = action.payload;

        return {
          ...state,
          story: {
            ...state.story
          },
          ui: {
            ...state.ui,
            storyElements: {
              ...state.ui.storyElements,
              [storyElementId]: { poll }
            }
          }
        };
      }
      case actions.UPDATE_OPINION_POLL: {
        const { storyElementId, key, value } = action.payload;
        return {
          ...state,
          ui: {
            ...state.ui,
            storyElements: {
              ...state.ui.storyElements,
              [storyElementId]: updateOpinionPollChanges(state.ui.storyElements[storyElementId], key, value)
            }
          }
        };
      }

      case actions.UPDATE_OPINION_POLL_IMAGE_UPLOAD_STATUS: {
        return {
          ...state,
          ui: {
            ...state.ui,
            pollImageUploading: action.payload.uploading
          }
        };
      }

      case actions.SET_IMAGES_FOR_IMAGE_GALLERY_ELEMENT: {
        // const { storyElementId, images } = action.payload;
        return produce((draft) => {
          draft.story["story-elements"] = [];
        });
      }

      case actions.STORY_EDITOR_MOVE_STORY_ELEMENT_UP: {
        const { imageStoryElement, parentStoryElement } = action.payload;

        const { story, editorState } = moveStoryElementUpInCompositeElements(
          state.story,
          state.editorState as EditorState,
          imageStoryElement as ChildStoryElement,
          parentStoryElement as CompositeStoryElement
        );

        return updateStoryAndEditorState(state, story, editorState, {});
      }

      case actions.STORY_EDITOR_MOVE_STORY_ELEMENT_DOWN: {
        const { imageStoryElement, parentStoryElement } = action.payload;
        const { story, editorState } = moveStoryElementDownInCompositeElements(
          state.story,
          state.editorState as EditorState,
          imageStoryElement as ChildStoryElement,
          parentStoryElement as CompositeStoryElement
        );
        return updateStoryAndEditorState(state, story, editorState, {});
      }

      case actions.SET_OPINION_POLL_INIT: {
        const { storyElementId } = action.payload;
        return {
          ...state,
          ui: {
            ...state.ui,
            storyElements: {
              ...state.ui.storyElements,
              [storyElementId]: { ...state.ui.storyElements[storyElementId], saving: true }
            }
          }
        };
      }

      case actions.SET_OPINION_POLL_SUCCESS: {
        const { storyElementId } = action.payload;
        return {
          ...state,
          ui: {
            ...state.ui,
            storyElements: {
              ...state.ui.storyElements,
              [storyElementId]: { ...state.ui.storyElements[storyElementId], saving: false }
            }
          }
        };
      }

      case actions.IMPORT_CARD: {
        const { card, storyElements } = action.payload;
        const { story, editorState } = appendCard(state.story, state.editorState as EditorState, card, storyElements);
        const cardsLoaded = [...state.ui.cardsLoaded, card["content-id"]];
        const recentlyAddedCardId = card.id;
        const uiOpts =
          story["story-template"] === "live-blog"
            ? { cardsLoaded, recentlyAddedCardId, numberOfCardsShown: state.ui.numberOfCardsShown + 1 }
            : { cardsLoaded, recentlyAddedCardId };

        return updateStoryAndEditorState(state, story, editorState, uiOpts);
      }

      case actions.SET_FORMATTING_TOOLBAR_ACTIVE: {
        return produce(state, (draft) => {
          draft.ui.isFormattingToolbarActive = true;
        });
      }

      case actions.SET_FORMATTING_TOOLBAR_INACTIVE: {
        return produce(state, (draft) => {
          draft.ui.isFormattingToolbarActive = false;
        });
      }

      case actions.SET_TEXT_PARAPHRASING_STATUS: {
        return {
          ...state,
          ui: {
            ...state.ui,
            isTextParaphrasingInProgress: action.payload
          }
        };
      }
      default:
        return state;
    }
  } catch (error) {
    w.newrelic &&
      w.newrelic.noticeError(error, {
        errorType: `story-conent-reducer-${action.type}`,
        errorDescription: "Exception in reducer",
        errorInfo: JSON.stringify({ msg: error.message }),
        reduxActionLog: JSON.stringify({ actions: getReduxActionLog() })
      });
    return state;
  }
}
