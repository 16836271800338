/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import classnames from "classnames/bind";
import { connect } from "react-redux";
import { actions } from "../actions";
import SolidPin from "components/icons/solid-pin";
import ListicleCard from "./listicle-card";
import { deleteCardAction, shareCardAction } from "../async-action-creators";
import ElementsToolbar from "../elements-toolbar/elements-toolbar";
import { NOTIFICATION_ERROR, NOTIFICATION_INFO } from "containers/page/actions";
import ExclamationCircle from "components/icons/exclamation-circle";

import { isEmptyObject } from "utils/object.utils";
import { isEmpty } from "lodash";
import { getErrorMessage, cardErrorCodes, getCardLevelErrorMessage } from "pages/story-editor/utils";
import { t } from "i18n";
import Tooltip from "components/tooltip/tooltip";
import styles from "./story-card.module.css";
import { StoryElementDirection } from "../operations/story-elements/add";
import MobileCardEditor from "./mobile-card-editor";
import DesktopCardEditor from "./desktop-card-editor";
import { ThunkDispatch } from "redux-thunk";
import { Node } from "prosemirror-model";
import { CardId } from "api/primitive-types";
import EmptyPin from "components/icons/empty-pin";
import EmptyStar from "components/icons/empty-star";
import SolidStar from "components/icons/solid-star";
import { EditorView } from "prosemirror-view";
import { selectIsDesktopSizeViewport } from "store/viewport";
import { PartialAppState, CardErrors } from "../state";
import { makeCardWordCountSelector, makeCardCharacterCountSelector, selectIsReadOnly } from "../selectors";

const cx = classnames.bind(styles);

interface DispatchProps {
  deleteCard: () => void;
  moveCardDown: () => void;
  moveCardUp: () => void;
  pinCard: () => void;
  addKeyEvent: () => void;
  shareCard: () => void;
  showSnackBar: (message: string) => void;
  cardErrorNotification: (message: string) => void;
  cardInfoNotification: (message: string) => void;
}
export interface stateOwnProps {
  node: Node;
  view: EditorView;
}
interface StateProps {
  storyTemplate: string;
  isPinned?: boolean;
  keyEvent?: string;
  cardType: string;
  errors: CardErrors;
  totalCards: number;
  storyElementsCount: number;
  cardIndex: number;
  cardId: CardId;
  isCardDisabled: boolean;
  hasIntroductionCard: boolean | undefined;
  recentlyAddedCardId: string | null;
  wordCount: number | null;
  characterCount: number | null;
  isDesktopSizeViewport: boolean;
  isSyndicatedReadOnlyCard?: boolean;
  isReadOnly: boolean;
  isInspectorActive: boolean;
}

interface StoryCardProps {
  contentDOM: HTMLElement | null;
}

interface dispatchOwnProps {
  node: Node;
}

type interfaceProps = StoryCardProps & DispatchProps & StateProps & dispatchOwnProps;

class StoryCard extends React.PureComponent<interfaceProps> {
  private cardRef: React.RefObject<HTMLDivElement>;
  cardDom: HTMLDivElement | null;
  constructor(props: interfaceProps) {
    super(props);
    this.cardDom = null;
    this.cardRef = React.createRef();
  }

  componentDidMount() {
    if (this.props.recentlyAddedCardId === this.props.cardId && this.cardRef.current) {
      this.cardRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
    }

    this.appendContentDOM(this.cardRef);
  }

  appendContentDOM = (ref: React.RefObject<HTMLDivElement>) => {
    ref && ref.current && this.props.contentDOM && ref.current.appendChild(this.props.contentDOM);
  };

  isListicleTemplate = () => ["listicle", "story-listicle"].includes(this.props.storyTemplate);

  showReadOnlyWarning = () => this.props.showSnackBar(t("story-editor.read_only_mode"));

  isEditable = () => !this.props.isReadOnly;

  isDesktop = () => this.props.isDesktopSizeViewport;

  isMobile = () => !this.isDesktop();

  hasNoStoryElements = () => this.props.storyElementsCount === 0;

  shouldComponentUpdate(prevProps: interfaceProps) {
    return (
      prevProps.errors !== this.props.errors ||
      prevProps.storyTemplate !== this.props.storyTemplate ||
      prevProps.hasIntroductionCard !== this.props.hasIntroductionCard ||
      prevProps.node.attrs["story-version-id"] !== this.props.node.attrs["story-version-id"]
    );
  }

  render() {
    const isInspectorClass = this.props.isInspectorActive ? "is-open" : "";
    const storyEditorCard = cx("story-editor-card", {
      "block-card": this.props.isCardDisabled,
      "is-open": isInspectorClass
    });

    return (
      <div
        ref={(element) => (this.cardDom = element)}
        className={storyEditorCard}
        data-test-id="story-editor-card"
        {...(this.props.isCardDisabled ? { contentEditable: false } : {})}>
        {this.showHeader()}
        {this.hasNoStoryElements() && this.showAddElementsToolbar()}
        <div
          className={styles["story-editor-card-content"]}
          data-test-id="story-editor-card-content"
          ref={this.cardRef}
        />
        {this.showWordCharacterCount()}
      </div>
    );
  }

  private showHeader() {
    const cardErrorMessage = this.getCardErrorMessage(this.props.errors, this.props.cardId);

    return (
      <div
        className={styles["story-editor-card-header"]}
        data-test-id="story-editor-card-header"
        contentEditable={false}>
        {this.isListicleTemplate() && this.showCardNumbers()}

        {!isEmpty(cardErrorMessage) && (
          <div className={styles["story-editor-card-error-message"]} data-test-id="story-editor-card-error-message">
            <ExclamationCircle color="#ff214b" width="16" height="16" />
            <span>{cardErrorMessage}</span>
          </div>
        )}

        {this.props.storyTemplate === "live-blog" && this.showFavoriteOption()}

        <div className={styles["story-editor-card-actions"]} data-test-id="story-editor-card-actions">
          {this.props.storyTemplate === "live-blog" && this.showPinOption()}
          {this.isEditable() && this.isDesktop() && this.showDefaultDesktopHeaderActions()}
        </div>
        {this.isEditable() && this.isMobile() && this.showDefaultMobileHeaderActions()}
      </div>
    );
  }

  private getCardErrorMessage(errors: CardErrors, cardId: CardId) {
    if (!isEmptyObject(errors) && errors[cardId]) {
      const cardError = errors[cardId];
      if (cardError.code && cardErrorCodes.includes(cardError.code)) {
        const message = getErrorMessage({
          code: cardError.code,
          type: cardError.type,
          subtype: cardError.subtype
        });
        this.props.cardErrorNotification(message);
      } else if (errors[cardId].code === "must-have-title-story-element-if-key-event") {
        return t("story-editor.must-have-title-element");
      } else {
        return getCardLevelErrorMessage(cardError);
      }
    } else {
      return "";
    }
  }

  private showCardNumbers() {
    return (
      <ListicleCard
        index={this.props.cardIndex}
        totalCards={this.props.totalCards}
        cardType={this.props.cardType}
        hasIntroductionCard={this.props.hasIntroductionCard}
      />
    );
  }

  private showFavoriteOption() {
    const favoriteClickHandler = this.isEditable() ? this.props.addKeyEvent : this.showReadOnlyWarning;
    return (
      <>
        <div
          tabIndex={0}
          className={styles["story-editor-card-key-event"]}
          data-tip
          data-for={`live-blog-key-event-${this.props.cardIndex}`}
          onClick={favoriteClickHandler}>
          {this.props.keyEvent ? <SolidStar fill={"#4860bc"} /> : <EmptyStar />}
        </div>
        {this.isDesktop() && (
          <Tooltip
            id={`live-blog-key-event-${this.props.cardIndex}`}
            place="bottom"
            effect="solid"
            value={
              this.props.keyEvent ? t("story-editor.story-card.key-event") : t("story-editor.story-card.add-key-event")
            }
          />
        )}
      </>
    );
  }

  private showPinOption() {
    const className = cx("story-editor-card-action", { pin: this.props.isPinned });
    const pinClickHandler = this.isEditable() ? this.props.pinCard : this.showReadOnlyWarning;

    return (
      <>
        <div
          tabIndex={0}
          data-tip
          data-for={`live-blog-pin-${this.props.cardIndex}`}
          onClick={pinClickHandler}
          className={className}>
          {this.props.isPinned ? <SolidPin fill={"#4860bc"} /> : <EmptyPin />}
        </div>
        {this.isDesktop() && (
          <Tooltip
            id={`live-blog-pin-${this.props.cardIndex}`}
            place="bottom"
            effect="solid"
            value={this.props.isPinned ? t("story-editor.story-card.unpin") : t("story-editor.story-card.pin")}
          />
        )}
      </>
    );
  }

  private showDefaultDesktopHeaderActions() {
    const attributesClass = cx("story-editor-card-action", {
      "card-has-error": this.props.errors[this.props.cardId] && this.props.errors[this.props.cardId].metadata
    });

    return (
      <div className={styles["story-editor-card-action-desktop"]} data-test-id="story-editor-card-action-desktop">
        <DesktopCardEditor {...this.props} attributesClass={attributesClass} />
      </div>
    );
  }

  private showDefaultMobileHeaderActions() {
    return (
      <div
        className={styles["story-editor-card-action-mobile"]}
        data-test-id="story-editor-card-action-mobile"
        tabIndex={0}>
        <MobileCardEditor {...this.props} />
      </div>
    );
  }

  private showAddElementsToolbar() {
    return (
      <div className={styles["story-editor-card-add-element"]} data-test-id="story-editor-card-add-element">
        <ElementsToolbar
          emptyCardId={this.props.cardId}
          currentStoryElement={null}
          addNewElementAt={StoryElementDirection.TOP}
        />
      </div>
    );
  }

  private showWordCharacterCount() {
    const { wordCount = 0, characterCount = 0 } = this.props;

    return (
      <div
        className={styles["story-editor-card-wordcount"]}
        data-test-id="story-editor-card-wordcount"
        contentEditable={false}>
        {`${wordCount} ${t("story-editor.storyWordCountMsg", {
          count: wordCount
        })} / ${characterCount} ${t("story-editor.storyCharacterCountMsg", { count: characterCount })}`}
      </div>
    );
  }
}

const makeMapStateToProps = () => {
  // make selector pattern used to memoize a separate selector for each instance of story card
  // TODO fix the performance of the selector.
  const selectCardWordCount = makeCardWordCountSelector();
  const selectCardCharacterCount = makeCardCharacterCountSelector();
  const mapStateToProps = (state: PartialAppState, ownProps: stateOwnProps): StateProps => {
    const cardId = ownProps.node.attrs.id;
    const card = state.storyEditor.story.cards[cardId];
    const cardMetadata = card && card.metadata;
    const story = state.storyEditor.story;
    const storyTree = story.tree;
    const cardIndex = storyTree.findIndex((item: any) => item["content-id"] === cardId);
    const hasIntroductionCard = (storyTree[0] && storyTree[0]["card-type"] === "introduction") || false;
    const inspectorData = state.storyEditor.ui.inspector;

    return {
      storyTemplate: story["story-template"],
      isPinned: cardMetadata && cardMetadata.attributes ? cardMetadata.attributes["is-pinned?"] : false,
      keyEvent: cardMetadata && cardMetadata.attributes ? cardMetadata.attributes["key-event"] : false,
      cardType: card && card["card-type"],
      errors: state.storyEditor.ui.errors.editor.cards ? state.storyEditor.ui.errors.editor.cards : {},
      totalCards: storyTree.length,
      storyElementsCount: card && card.tree.length,
      cardIndex,
      cardId,
      isReadOnly: selectIsReadOnly(state),
      isCardDisabled: ownProps.node.attrs.isCardDisabled || state.storyEditor.ui.isStoryLocked,
      hasIntroductionCard,
      recentlyAddedCardId: state.storyEditor.ui.recentlyAddedCardId,
      wordCount: selectCardWordCount(state, ownProps),
      characterCount: selectCardCharacterCount(state, ownProps),
      isDesktopSizeViewport: selectIsDesktopSizeViewport(state),
      isSyndicatedReadOnlyCard: ownProps.node.attrs.isCardDisabled,
      isInspectorActive: inspectorData && inspectorData.active
    };
  };
  return mapStateToProps;
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, any>, ownProps: dispatchOwnProps): DispatchProps => {
  const {
    MOVE_STORY_EDITOR_CARD_UP,
    MOVE_STORY_EDITOR_CARD_DOWN,
    STORY_EDITOR_PIN_CARD,
    STORY_EDITOR_ADD_KEY_EVENT
  } = actions;
  return {
    deleteCard: () => dispatch(deleteCardAction(ownProps.node.attrs.id)),
    moveCardDown: () => dispatch({ type: MOVE_STORY_EDITOR_CARD_DOWN, payload: { cardId: ownProps.node.attrs.id } }),
    moveCardUp: () => dispatch({ type: MOVE_STORY_EDITOR_CARD_UP, payload: { cardId: ownProps.node.attrs.id } }),
    pinCard: () => dispatch({ type: STORY_EDITOR_PIN_CARD, payload: { cardId: ownProps.node.attrs.id } }),
    addKeyEvent: () => dispatch({ type: STORY_EDITOR_ADD_KEY_EVENT, payload: { cardId: ownProps.node.attrs.id } }),
    shareCard: () => dispatch(shareCardAction(ownProps.node.attrs.id)),
    showSnackBar: (message) => dispatch({ type: NOTIFICATION_INFO, payload: { message, action: null } }),
    cardErrorNotification: (message) => dispatch({ type: NOTIFICATION_ERROR, payload: { message, action: null } }),
    cardInfoNotification: (message) => dispatch({ type: NOTIFICATION_INFO, payload: { message, action: null } })
  };
};

export default connect(makeMapStateToProps, mapDispatchToProps)(StoryCard);

export { StoryCard };
