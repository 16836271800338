/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import Bold from "./bold";
import Italic from "./italic";
import SuperScript from "./superscript";
import SubScript from "./subscript";
import BulletList from "./bullet-list";
import RemoveFormatting from "./remove-formatting";
import Underline from "./underline";
import StrikeThrough from "./strike-through";
import Link from "./link";
import NumberedList from "./numbered-list";
import H2 from "./h2";
import H3 from "./h3";
import H4 from "./h4";
import H5 from "./h5";
import H6 from "./h6";

export {
  Bold,
  Italic,
  SuperScript,
  SubScript,
  BulletList,
  RemoveFormatting,
  Underline,
  StrikeThrough,
  Link,
  NumberedList,
  H2,
  H3,
  H4,
  H5,
  H6
};
