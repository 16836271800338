/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import { connect } from "react-redux";
import classnames from "classnames/bind";
import { compose } from "redux";
import ReactTable from "react-table";
import { format } from "date-fns";
import wrapPage from "containers/page/page";
import SearchBar from "components/search-bar/search-bar";
import Button from "components/button/button";
import TablePagination from "components/table-pagination/table-pagination";
import {
  loadEntities,
  getEntitiesByPageAction,
  createOrUpdateEntityAction,
  setSelectedEntityTypeAction,
  editEntityAction,
  deleteEntityAction,
  setEntityImageAction,
  setSearchTermAction
} from "./action-creators";
import { ENTITIES_ADD_NEW, ENTITIES, ENTITIES_CHOOSE_IMAGE, ENTITIES_EDIT_IMAGE } from "./routes";
import { goBack, navigateFn } from "../../../utils/routes.utils";
import * as actions from "./actions";
import Inspector from "components/inspector/inspector";
import Select from "components/select/select";
import EntityTemplate from "pages/manage/entities/components/entity-template/entity-template";
import MediaGallery from "pages/media-library/components/media-gallery/media-gallery";
import ImageUpload from "pages/media-library/components/image-upload/image-upload";
import styles from "./entities.module.css";
import { t } from "../../../i18n";
import Loader from "./loader";
import LoaderWrapper from "behaviors/loader/components/loader-wrapper/loader-wrapper";
import { getEntities } from "helpers/api";

const cx = classnames.bind(styles);

class Entities extends React.Component<any, any> {
  componentDidMount() {
    this.props.fetchEntities({
      limit: this.props.pagination.limit
    });
  }

  getEntityId = () =>
    this.props.selectedEntityData && this.props.selectedEntityData.id ? this.props.selectedEntity.id : "new";

  render() {
    return (
      <React.Fragment>
        <section className={styles["page-container entities-container"]}>
          <main className={styles["entities-wrapper"]}>
            <header className={styles["entities-header"]} data-test-id="entities-header">
              <h1 className={styles["entities-title"]} data-test-id="entities-header-title">
                {t("entities.entities")}
              </h1>
              {/* <div className="entities-action-button">
                              <Button type="secondary">Import Data</Button>
                            </div> */}
              <div className={styles["entities-action-button"]}>
                <Button testId="entities-action-btn" type="secondary" onClick={() => this.props.addNewEntity()}>
                  {t("entities.add_entity")}
                </Button>
              </div>
            </header>
            <div
              className={cx("entities-table-container", `${this.props.loader.loading ? "entities-is-loading" : ""}`)}
              data-test-id="entities-table-container">
              <div className={styles["pagination-top"]} data-test-id="pagination-top">
                <div className={styles["entities-searchbar"]}>
                  <SearchBar
                    placeholder={t("entities.search_placeholder")}
                    value={this.props.searchTerm}
                    onChange={(searchTerm) => this.props.setSearchTerm(searchTerm)}
                  />
                </div>
                <TablePagination
                  specifiedPage={this.props.currentPage}
                  onPageChange={(pageNumber) => this.props.getEntitiesByPage(pageNumber)}
                  totalCount={this.props.pagination.total}
                  perPage={this.props.entitiesPerPage}
                />
              </div>
              <LoaderWrapper
                className={styles["entities-progress-main-area"]}
                component={Loader}
                loader={this.props.loader}>
                <div data-test-id="entities-react-table">
                  <ReactTable
                    showPaginationTop={false}
                    showPaginationBottom={false}
                    showPagination={false}
                    loading={false}
                    showPageSizeOptions={false}
                    defaultPageSize={1}
                    sortable={false}
                    pageSize={
                      this.props.entities.length > this.props.entitiesPerPage
                        ? this.props.entitiesPerPage
                        : this.props.entities.length
                    }
                    noDataText={
                      this.props.loader.loading ? `${t("entities.fetching")}..` : t("entities.no_entity_found")
                    }
                    data={this.props.entities}
                    columns={[
                      {
                        Header: t("entities.table.name"),
                        accessor: "name"
                      },
                      {
                        Header: t("entities.table.type"),
                        accessor: "type",
                        style: { padding: "0 10px" },
                        headerStyle: { padding: "0 10px" }
                      },
                      {
                        Header: t("entities.table.created_on"),
                        id: "createdAt",
                        accessor: (entity) => format(entity["created-at"], "dd MMM yyyy"),
                        maxWidth: 150,
                        style: { padding: "0 10px" },
                        headerStyle: { padding: "0 10px" }
                      }
                    ]}
                    onPageChange={(pageNumber) => this.props.getEntitiesByPage(pageNumber)}
                    pages={Math.ceil(this.props.pagination.total / this.props.entitiesPerPage)}
                    className={styles["entities-table"]}
                    getTrProps={(state, rowInfo) => ({
                      onClick: () => this.props.hasEntityEditPermission && this.props.editEntity(rowInfo.original)
                    })}
                    LoadingComponent={() => null}
                  />
                </div>
              </LoaderWrapper>
            </div>
          </main>
        </section>
        <Inspector
          title={t(`entities.${this.props.inspectorLabel}`)}
          actionButtonLabel={
            this.props.inspectorActionButtonLabel ? t(`entities.${this.props.inspectorActionButtonLabel}`) : null
          }
          onActionButtonClick={() =>
            this.props.inspectorLevel === 0 ? this.props.createOrUpdateEntity() : this.props.setEntityImage()
          }
          isActive={this.props.showInspector}
          onClose={() => this.props.closeAddNewInspector()}
          isActionButtonDisabled={!this.props.selectedEntity || this.props.uploading}
          variant={this.props.inspectorVariant}
          level={this.props.inspectorLevel}>
          <div className={styles["entities-edit-container"]}>
            {this.props.showAddNewInspector && (
              <React.Fragment>
                <Select
                  value={this.props.selectedEntity}
                  onChange={(entityType) => this.props.setSelectedEntityType(entityType)}
                  getOptionLabel={(entityType) => entityType.name}
                  getOptionValue={(entityType) => entityType.id}
                  options={this.props.entityTypes}
                  label={t("entities.type")}
                  placeholder={t("entities.select_entity_type")}
                  helpText={t("entities.choose_entity_type")}
                />
              </React.Fragment>
            )}
            {this.props.showAddNewInspector && this.props.selectedEntity && (
              <EntityTemplate
                selectedEntity={this.props.selectedEntity}
                updateSelectedEntity={this.props.updateSelectedEntity}
                entityTemplateOptions={this.props.entityTemplateOptions}
                getEntities={getEntities}
                selectedEntityData={this.props.selectedEntityData}
                fieldErrors={this.props.fieldErrors}
                getEntityId={this.getEntityId}
                uploadImage={this.props.uploadImage}
                removeEntityImages={this.props.removeEntityImages}
                setSelectedEntityImages={this.props.setSelectedEntityImages}
                showEditImage={true}
                switchToUploadRoute={(mediaKey) => this.props.switchToUploadRoute(this.getEntityId(), mediaKey)}
                updateImageUploadStatus={(status) => this.props.updateSelectedEntityImagesUploadStatus(status)}
              />
            )}
            {this.props.showAddNewInspector &&
              this.props.selectedEntityData &&
              this.props.selectedEntityData.id &&
              this.props.hasEntityDeletePermission && (
                <div className={styles["entities-delete-action-button"]}>
                  <Button
                    testId="entities-inspector-delete-btn"
                    type="secondary"
                    variant="danger"
                    onClick={() => this.props.deleteEntity(this.props.selectedEntityData.id)}>
                    {t("entities.delete")}
                  </Button>
                </div>
              )}
            {this.props.showImageInspector && (
              <MediaGallery
                showSelectMediaProvider={true}
                switchToUploadRoute={(mediaKey) => this.props.switchToUploadRoute(this.getEntityId(), mediaKey)}
                setSelectedMedia={(images) => this.props.setSelectedEntityImages(images)}
                updateImageUploadStatus={(status) => this.props.updateSelectedEntityImagesUploadStatus(status)}
                showEditImage={true}
                scrollableTarget="inspector-container"
              />
            )}
            {this.props.showImageEditInspector && (
              <ImageUpload
                selectedImages={this.props.selectedImages}
                updateImageData={(key, value, mediaIndex) =>
                  this.props.updateSelectedEntityImagesData(key, value, mediaIndex)
                }
                isUploading={this.props.uploading}
                onDeleteMedia={this.props.goToPrevRoute}
                isLinkAuthorEnabled={false}
                errors={this.props.fieldErrors && this.props.fieldErrors.image}
                isStoryImageHyperlinkEnabled={this.props.isStoryImageHyperlinkEnabled}
              />
            )}
          </div>
        </Inspector>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    entities: state.entities.entitiesPage.entities,
    pagination: state.entities.entitiesPage.pagination,
    entitiesPerPage: state.entities.entitiesPage.entitiesPerPage,
    currentPage: state.entities.ui.currentPage,
    searchTerm: state.entities.ui.searchTerm,
    entityTypes: state.config.entityTypes,
    selectedEntity: state.entities.entitiesPage.selectedEntity,
    entityTemplateOptions: state.entities.entityTemplateOptions,
    selectedEntityData: state.entities.selectedEntityData,
    fieldErrors: state.entities.ui.errors,
    loader: state.entities.ui.main,
    selectedImages: state.entities.entitiesPage.selectedEntityImages,
    uploading: state.entities.ui.imageUploading,
    hasEntityEditPermission: state.features.hasEntityEditPermission,
    hasEntityDeletePermission: state.features.hasEntityDeletePermission,
    isStoryImageHyperlinkEnabled: state.features.enableStoryImageHyperlink
  };
};

const mapDispatchToProps = (dispatch) => {
  const navigate = navigateFn(dispatch);

  return {
    navigate,
    fetchEntities: (params) => dispatch(loadEntities(params)),
    getEntitiesByPage: (pageNumber) => dispatch(getEntitiesByPageAction(pageNumber)),
    setSearchTerm: (searchTerm) => dispatch(setSearchTermAction(searchTerm)),
    addNewEntity: () => navigate(ENTITIES_ADD_NEW),
    closeAddNewInspector: () => {
      navigate(ENTITIES);
      dispatch({
        type: actions.ENTITIES_RESET_SELECTED_ENTITY
      });
    },
    setSelectedEntityType: (entityType) => dispatch(setSelectedEntityTypeAction(entityType)),
    updateSelectedEntity: (key, value) =>
      dispatch({
        type: actions.ENTITIES_UPDATE_SELECTED_ENTITY,
        payload: {
          key,
          value
        }
      }),
    createOrUpdateEntity: () => dispatch(createOrUpdateEntityAction()),
    editEntity: (entity) => dispatch(editEntityAction(entity)),
    deleteEntity: (id) => dispatch(deleteEntityAction(id)),
    uploadImage: (id) =>
      navigate(ENTITIES_CHOOSE_IMAGE, {
        id
      }),
    goToPrevRoute: () => dispatch(goBack()),
    switchToUploadRoute: (id, mediaKey) =>
      navigate(ENTITIES_EDIT_IMAGE, {
        id,
        mediaKey
      }),
    setSelectedEntityImages: (images) => {
      let modifiedImages = images.map((image) => {
        let key = image["temp-image-key"];
        delete image["temp-image-key"];
        return { ...image, "temp-key": key };
      });
      dispatch({
        type: actions.ENTITIES_SET_SELECTED_ENTITY_IMAGES,
        payload: {
          images: modifiedImages
        }
      });
    },
    updateSelectedEntityImagesData: (key, value, imageIndex) =>
      dispatch({
        type: actions.ENTITIES_UPDATE_SELECTED_ENTITY_IMAGES_DATA,
        payload: {
          key,
          value,
          imageIndex
        }
      }),
    updateSelectedEntityImagesUploadStatus: (status) =>
      dispatch({
        type: actions.ENTITIES_UPDATE_SELECTED_ENTITY_IMAGES_UPLOAD_STATUS,
        payload: {
          status
        }
      }),
    setEntityImage: () => dispatch(setEntityImageAction()),
    removeEntityImages: () => {
      dispatch({
        type: actions.ENTITIES_SET_SELECTED_ENTITY_IMAGES,
        payload: {
          images: []
        }
      });
      dispatch({
        type: actions.ENTITIES_UPDATE_SELECTED_ENTITY,
        payload: {
          key: "photo",
          value: []
        }
      });
    }
  };
};

export default compose<any, any, any>(connect(mapStateToProps, mapDispatchToProps), wrapPage())(Entities);

export { Entities };
