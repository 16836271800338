/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import AsyncCreatableSelect from "components/select/async-creatable";
import AsyncSelect from "components/select/async";
import pDebounce from "p-debounce";
import { getErrorMessage } from "pages/story-editor/utils";
import { t } from "i18n";
import { searchTags, Tag } from "api/tag";
import { listEntities, Entity } from "api/entity";
import { ValidationError } from "api/breaking-news";

async function searchTagItems(term: string): Promise<Array<TagEntityOptionType>> {
  const [{ tags }, { entities }] = await Promise.all([searchTags(term), listEntities(term || "")]);
  const tagsWithType = tags.map((item: Tag) => {
    return { ...item, "tag-type": "Tag", displayName: `${item.name} ( Tag )` };
  });
  const entitiesWithType = entities.map((item: Entity) => {
    return { ...item, "tag-type": "Entity", displayName: `${item.name} ( ${item.type} )` };
  });
  console.log(entitiesWithType, "ents");
  return [...entitiesWithType, ...tagsWithType];
}

const debouncedSearchTags = pDebounce(searchTagItems, 500);

function updateValue(items: Array<Tag | Entity>): Array<TagEntityOptionType> {
  return items && items.map((item) => ({ ...item, displayName: `${item.name} ( ${item["type"] || "Tag"} )` }));
}

function onChange(
  tags: Array<TagEntityOptionType | NewTagOption>,
  onTagChange: (Tags: Array<Tag | Entity | NewTagOption>) => void
) {
  const latestTag = tags.pop();
  latestTag ? onTagChange([...tags, latestTag]) : onTagChange([]);
}

interface TagsProps {
  value: Array<Tag | Entity>;
  onTagChange(Tags: Array<Tag | Entity>): void;
  errors?: ValidationError | null;
  hasTagCreatePermission: boolean;
  disabled?: boolean;
}

interface NewTagOption {
  name: string;
  displayName: string;
}

const Tags = ({ value, onTagChange, errors, hasTagCreatePermission, disabled }: TagsProps) => {
  return (
    <React.Fragment>
      {hasTagCreatePermission ? (
        <AsyncCreatableSelect<TagEntityOptionType | NewTagOption>
          value={updateValue(value)}
          onChange={(tags) => Array.isArray(tags) && onChange(tags!, onTagChange)}
          getNewOptionData={(inputValue, optionLabel) => ({
            name: inputValue,
            displayName: `${optionLabel} ( Tag )`
          })}
          getOptionLabel={(tag) => tag.displayName}
          getOptionValue={(tag) => tag["name"] || tag["label"]}
          loadOptions={(term) => debouncedSearchTags(term).then((tags) => tags)}
          isMulti={true}
          label={t("story-editor.inspector.tags")}
          defaultOptions={false}
          isValidNewOption={(inputValue, selectValue, selectOptions) =>
            hasTagCreatePermission
              ? inputValue.trim().length === 0 || selectOptions.find((option) => option.name === inputValue)
                ? false
                : true
              : false
          }
          createOptionPosition="first"
          errorMessage={errors === undefined ? undefined : getErrorMessage(errors)}
          isDisabled={disabled}
        />
      ) : (
        <AsyncSelect<TagEntityOptionType>
          value={updateValue(value)}
          onChange={(tags) => Array.isArray(tags) && onChange(tags!, onTagChange)}
          getOptionLabel={(tag) => tag.displayName}
          getOptionValue={(tag) => tag.name}
          loadOptions={(term) => debouncedSearchTags(term).then((tags) => tags)}
          isMulti={true}
          label={t("story-editor.inspector.tags")}
          defaultOptions={false}
          errorMessage={errors === undefined ? undefined : getErrorMessage(errors)}
          isDisabled={disabled}
        />
      )}
    </React.Fragment>
  );
};

type TagEntityOptionType = (Tag | Entity) & {
  displayName: string;
};

interface SingleTagOrEntityProps {
  value: TagEntityOptionType | Tag | Entity | null;
  onTagChange(Tag: TagEntityOptionType): void;
  label: string;
  errorMessage?: string;
}

const SingleTagOrEntity: React.SFC<SingleTagOrEntityProps> = ({ value, onTagChange, label, errorMessage }) => {
  function updateValue(item: TagEntityOptionType | Tag | Entity | null): TagEntityOptionType | null {
    if (item) {
      const displaySuffix = item["tag-type"] === "Entity" ? item["type"] : "Tag";
      return { ...item, displayName: `${item.name} ( ${displaySuffix} )` };
    }
    return item;
  }

  function onChange(onTagChange: (Tag: TagEntityOptionType) => void, tag?: TagEntityOptionType | null) {
    tag && onTagChange(tag);
  }

  return (
    <AsyncSelect<TagEntityOptionType>
      value={updateValue(value)}
      onChange={(tag) => Array.isArray(tag) || onChange(onTagChange, tag)}
      getOptionLabel={(tag) => tag.displayName}
      getOptionValue={(tag) => tag.id.toString()}
      loadOptions={(term) => debouncedSearchTags(term).then((tags) => tags)}
      label={label}
      defaultOptions={false}
      errorMessage={errorMessage || ""}
    />
  );
};

export default Tags;

export { SingleTagOrEntity };
