/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import React from "react";
import { Draggable } from "react-beautiful-dnd";

const ScrollableItem = ({ id, draggableId, index, children }) => {
  return (
    <Draggable key={id} draggableId={draggableId.toString()} index={index}>
      {(provided, snapshot) => (
        <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
          {children}
        </div>
      )}
    </Draggable>
  );
};

export default ScrollableItem;
