/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { StoryId } from "./primitive-types";
import { Story } from "./story";
import { SocialCard } from "./social";
import { client } from "./client";
import { FocusPoint } from "./search-media-image";

export interface LinkedStory {
  headline: string;
  "story-content-id": string;
  "highlighted-text"?: string;
  id: StoryId;
  "highlighted-headline"?: string;
}

export type AssociatedStory = Pick<
  Story,
  | "story-content-id"
  | "headline"
  | "subheadline"
  | "summary"
  | "hero-image"
  | "tree"
  | "cards"
  | "story-elements"
  | "status"
  | "sections"
  | "tags"
  | "entities"
  | "seo"
  | "metadata"
  | "authors"
  | "author-name"
  | "author-id"
  | "push-notification-targets"
  | "updated-cards"
  | "private-fields"
  | "publish-at"
> & {
  "custom-slug": string;
  "canonical-url": string;
};

export type StoryValidationFields = Pick<
  AssociatedStory,
  | "headline"
  | "subheadline"
  | "summary"
  | "sections"
  | "tags"
  | "authors"
  | "custom-slug"
  | "canonical-url"
  | "metadata"
> & {
  "hero-image-s3-key": string;
  "hero-image-attribution": string;
  "hero-image-alt-text": string;
  "hero-image-caption": string;
  "hero-image-focus-point": {
    "focus-point": FocusPoint;
  };
  cardContent: string;
  "meta-title": string;
  "meta-keywords": string;
  "meta-description": string;
};

export interface Metadata {
  "story-group"?: string;
  "breaking-news-type"?: string;
  "linked-story"?: LinkedStory;
  "collection-id"?: number;
}

export type BNSocialCard = Omit<SocialCard, "scheduleAt">;

export interface BreakingNews {
  "story-content-id": string;
  headline: string;
  "breaking-news-linked-story-id"?: StoryId;
  metadata: Metadata;
  "associated-story"?: AssociatedStory;
  "social-publish"?: BNSocialCard[];
  "social-post-content"?: string;
}

export interface BreakingNewsValidationFields {
  headline: string;
  "associated-story": StoryValidationFields;
}

export type ValidationError = {
  code: string;
  threshold?: number;
  type?: string;
  subtype?: string;
};

export type ValidationErrors<T> =
  | { [P in keyof T]?: T[P] extends object ? ValidationErrors<T[P]> : ValidationError }
  | null;

export const saveBreakingNews = (breakingNews: BreakingNews): Promise<any> => {
  return client
    .url("/api/breaking-news/new?format=itsman-x")
    .json(breakingNews)
    .post()
    .json();
};

export const saveBreakingNewsStory = (breakingNewsStory: Story): Promise<any> => {
  return client
    .url(`/api/breaking-news/${breakingNewsStory.id}?format=itsman-x`)
    .json(breakingNewsStory)
    .post()
    .json();
};
