/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { client } from "./client";

import { PublisherId, FeatureId, CategoryId } from "./primitive-types";

export interface ImageDimensions {
  width: number;
  height: number;
}

export type FocusPoint = [number, number];

export type ImageMetadata = ImageDimensions & {
  "focus-point": FocusPoint;
  "mime-type": string;
};

export interface BaseImage {
  "image-attribution": string;
  "image-caption": string;
  "image-metadata": ImageMetadata;
  "alt-text": string;
  "image-url": string;
}

export interface UnsavedImage extends BaseImage {
  "temporary-image-key": string;
}

export interface SavedImage extends BaseImage {
  "image-s3-key": string;
}

export type AnyImage = UnsavedImage | SavedImage;

export interface AssociatedStoryDefaults {
  text: string;
  "image-data": AnyImage;
}

export interface BasePublisherFeature {
  id: number;
  "publisher-id": PublisherId;
  "feature-id": FeatureId;
  name: string;
  description: string | null;
  slug: string;
  "default-config": any;
  "default-value": boolean;
  "default-value-for-lite-publisher": boolean;
  "config-schema": null;
  toggle: boolean;
  "category-id": CategoryId;
  "category-name": string;
  hidden: boolean;
  disabled: boolean;
  "sort-order": number;
  config: any;
}

export interface AssociatedStoryFeature extends BasePublisherFeature {
  config: AssociatedStoryDefaults;
}

export interface ErrorCode {
  code: string;
  threshold?: number;
  type?: string;
  subtype?: string;
}

export interface ValidationErrors {
  "image-data"?: {
    "image-attribution"?: ErrorCode;
    "image-caption"?: ErrorCode;
    "image-metadata"?: {
      "focus-point"?: ErrorCode;
    };
    "image-s3-key"?: ErrorCode;
    "image-url"?: ErrorCode;
    "alt-text"?: ErrorCode;
  };
  text?: ErrorCode;
}

export const getAssociatedStoryDefaults = (): Promise<AssociatedStoryFeature> => {
  return client
    .url("/api/associated-story-defaults")
    .get()
    .json();
};

export const saveDefaults = (data: AssociatedStoryDefaults): Promise<any> => {
  return client
    .url("/api/associated-story-defaults")
    .post({ defaults: data })
    .json();
};
