/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { connect } from "react-redux";
import { t } from "i18n";
import pDebounce from "p-debounce";

import { PartialAppState, StoryTemplateFields, PartialStoryTemplateFields, SocialAccount } from "./state";
import * as api from "api/breaking-news";
import {
  updateBreakingNews,
  setLinkType,
  updateStoryTemplateFields,
  saveStoryTemplateFields,
  enableBreakingNewsPublish,
  showSocialAccounts,
  updateSocialAccounts,
  updateMandatoryStoryAttributes
} from "./async-action-creators";
import { BreakingNewsType, AssociatedStoryDefaults } from "api/route-data/breaking-news-route-data";
import { LinkType, getLinkTypes, linkTypeValues } from "./constants";
import { getPublishedStories } from "helpers/api";
import { addLinkedStory, removeLinkedStory, getAutoFilledStoryTemplateFields } from "./utils";
import { Fields, FieldLimits } from "api/route-data/story-route-data";
import { Section } from "api/route-data/route-data";
import { BREAKING_NEWS_ADVANCED_OPTIONS, NEW_BREAKING_NEWS } from "./routes";
import classnames from "classnames/bind";

import SubTitle from "components/icons/sub-title";
import TextArea from "components/text-area/text-area";
import Select from "components/select/select";
import AsyncSelect from "components/select/async";
import {
  NonAutoFillableStoryFields,
  NonAutoFillableStoryFieldsValidationErrors
} from "./non-auto-fillable-story-fields";
import Button from "components/button/button";
import { navigateFn } from "utils/routes.utils";
import { AdvancedOptionsInspector } from "./components/advanced-options-inspector";
import TextLimitIndicator from "components/text-limit-indicator/text-limit-indicator";
import Checkbox from "components/checkbox/checkbox";
import { SocialAccountCheckbox } from "pages/story-editor/components/advanced-inspector/social-account";
import { getAttributesOfTypeEntityOptionsAction } from "pages/story-editor/async-action-creators";
import styles from "./breaking-news.module.css";
import { replaceArrayItemByIndex } from "utils";
import { Entity } from "api/entity";
import { Attribute } from "api/story-attributes";

const cx = classnames.bind(styles);

const debouncedPublishedStoriesAPI = pDebounce(
  (headline: string) => getPublishedStories({ headline, fields: "id,story-content-id,headline" }),
  250
);

interface OwnProps {
  showAdvancedOptionsInspector: boolean;
  isExistingBreakingNews?: boolean;
}

interface StateProps {
  breakingNews: api.BreakingNews;
  breakingNewsTypes: BreakingNewsType[];
  isBreakingNewsAssociateStoryEnabled: boolean;
  linkType: LinkType;
  fieldsSpec: Fields;
  fieldLimits: FieldLimits;
  sections: Section[];
  storyTemplateFields: StoryTemplateFields;
  savedStoryTemplateFields: PartialStoryTemplateFields;
  associatedStoryDefaults: AssociatedStoryDefaults;
  validationErrors: api.ValidationErrors<api.BreakingNewsValidationFields>;
  isSocialAccountsAvailable: boolean;
  socialAccounts: SocialAccount[];
  showSocialAccounts: boolean;
  hasTagCreatePermission: boolean;
  storyAttributes: Attribute[];
  isFreeTextAttributesEnabled: boolean;
}

interface DispatchProps {
  updateBreakingNews: (breakingNews: api.BreakingNews) => void;
  enableBreakingNewsPublish: (flag: boolean) => void;
  setLinkType: (linkType: LinkType) => void;
  showSocialAccountsFn: (showSocialAccounts: boolean) => void;
  updateSocialAccounts: (socialAccounts: SocialAccount[]) => void;
  updateStoryTemplateFields: (storyTemplateFields: StoryTemplateFields) => void;
  saveStoryTemplateFields: (storyTemplateFields: PartialStoryTemplateFields) => void;
  openAdvancedOptionsInspector: (storyTemplateFields: StoryTemplateFields) => void;
  cancelAdvancedOptionsSave: () => void;
  getAttributesOfTypeEntityOptions: (query: string, attributeValues: any) => void;
  onAttributeChange: (key: string, value: Array<Entity | string>) => void;
}

type BreakingNewsProps = OwnProps & StateProps & DispatchProps;

class BreakingNews extends React.Component<BreakingNewsProps> {
  linkTypes: LinkType[];
  constructor(props: BreakingNewsProps) {
    super(props);

    this.linkTypes = this.getLinkTypes();
    this.headlineChangeHandler = this.headlineChangeHandler.bind(this);
    this.typeChangeHandler = this.typeChangeHandler.bind(this);
    this.linkTypeChangeHandler = this.linkTypeChangeHandler.bind(this);
    this.linkedStoryChangeHandler = this.linkedStoryChangeHandler.bind(this);
    this.storyTemplateFieldChangeHandler = this.storyTemplateFieldChangeHandler.bind(this);
    this.advancedOptionsHandler = this.advancedOptionsHandler.bind(this);
    this.socialAccountsChangeHandler = this.socialAccountsChangeHandler.bind(this);
  }

  getLinkTypes(): LinkType[] {
    return this.props.isBreakingNewsAssociateStoryEnabled
      ? getLinkTypes()
      : getLinkTypes().filter((linkType) => linkType.value !== linkTypeValues.CREATE_ASSOCIATED_STORY);
  }

  headlineChangeHandler(value: string): void {
    const breakingNews: api.BreakingNews = { ...this.props.breakingNews, headline: value };
    this.props.updateBreakingNews(breakingNews);
    this.props.enableBreakingNewsPublish(!!value);
  }

  typeChangeHandler(type: BreakingNewsType): void {
    const breakingNews: api.BreakingNews = {
      ...this.props.breakingNews,
      metadata: {
        ...this.props.breakingNews.metadata,
        "story-group": type["story-group"],
        "breaking-news-type": type.name,
        "collection-id": type["collection-id"]
      }
    };

    this.props.updateBreakingNews(breakingNews);
  }

  linkTypeChangeHandler(linkType: LinkType) {
    this.props.setLinkType(linkType);
  }

  linkedStoryChangeHandler(story: api.LinkedStory | null) {
    const { breakingNews } = this.props;

    const updatedBreakingNews: api.BreakingNews = story
      ? addLinkedStory(breakingNews, story)
      : removeLinkedStory(breakingNews);

    this.props.updateBreakingNews(updatedBreakingNews);
  }

  storyTemplateFieldChangeHandler(partialStoryTemplateFields: PartialStoryTemplateFields) {
    const { storyTemplateFields, updateStoryTemplateFields } = this.props;

    updateStoryTemplateFields({ ...storyTemplateFields, ...partialStoryTemplateFields });
  }

  advancedOptionsHandler() {
    const autoFilledStoryTemplateFields = getAutoFilledStoryTemplateFields(
      this.props.fieldsSpec,
      this.props.storyTemplateFields,
      this.props.savedStoryTemplateFields,
      this.props.associatedStoryDefaults,
      this.props.breakingNews.headline
    );

    this.props.openAdvancedOptionsInspector(autoFilledStoryTemplateFields);
  }

  socialAccountsChangeHandler(index: number, updatedSocialAccount: SocialAccount) {
    const socialAccounts: SocialAccount[] = replaceArrayItemByIndex(
      this.props.socialAccounts,
      index,
      updatedSocialAccount
    );

    this.props.updateSocialAccounts(socialAccounts);
  }

  render() {
    const {
      breakingNews,
      isExistingBreakingNews,
      fieldLimits,
      breakingNewsTypes,
      linkType,
      isSocialAccountsAvailable,
      showSocialAccountsFn,
      showSocialAccounts,
      socialAccounts,
      hasTagCreatePermission
    } = this.props;

    return (
      <React.Fragment>
        <header className={styles["breaking-news-header"]}>
          <h1 className={styles["breaking-news-title"]} data-test-id="breaking-news-page-heading">
            {isExistingBreakingNews
              ? t("breaking-news.existing-breaking-news-page-title")
              : t("breaking-news.new-breaking-news-page-title")}
          </h1>
        </header>
        <section>
          <div className={styles["breaking-news-form-card"]}>
            <div className={styles["breaking-news-form-field-container--headline"]}>
              <div className={styles["breaking-news-form-field-icon"]}>
                <SubTitle />
                {fieldLimits["breaking-news"] && (
                  <TextLimitIndicator text={breakingNews.headline} limit={fieldLimits["breaking-news"].limit} />
                )}
              </div>
              <div className={styles["breaking-news-headline"]}>
                <TextArea
                  onChange={this.headlineChangeHandler}
                  placeholder={t("breaking-news.headline-placeholder")}
                  value={breakingNews.headline}
                  errorMessage=""
                  variant="plain"
                  size="jumbo"
                />
              </div>
            </div>
          </div>
          <div className={styles["breaking-news-form-card"]}>
            <div className={styles["breaking-news-form-container"]}>
              {!isExistingBreakingNews && (
                <div className={styles["breaking-news-form-field-container"]}>
                  <Select
                    name="breaking-news-type"
                    label={t("breaking-news.type-field-label")}
                    value={
                      breakingNews.metadata["story-group"] && breakingNews.metadata["breaking-news-type"]
                        ? {
                            "story-group": breakingNews.metadata["story-group"],
                            name: breakingNews.metadata["breaking-news-type"]
                          }
                        : null
                    }
                    options={breakingNewsTypes}
                    onChange={this.typeChangeHandler}
                    getOptionLabel={(breakingNewsType) => breakingNewsType.name}
                    getOptionValue={(breakingNewsType) => breakingNewsType["story-group"]}
                    isClearable={false}
                  />
                </div>
              )}
              {!isExistingBreakingNews && (
                <div className={styles["breaking-news-form-field-container"]}>
                  <Select
                    name="breaking-news-link"
                    label={t("breaking-news.link-field-label")}
                    value={linkType}
                    options={this.linkTypes}
                    onChange={this.linkTypeChangeHandler}
                    getOptionLabel={(linkType) => linkType.label}
                    getOptionValue={(linkType) => linkType.value}
                    isClearable={false}
                  />
                </div>
              )}
              {linkType && linkType.value === linkTypeValues.LINK_TO_EXISTING_STORY && (
                <div className={styles["breaking-news-form-field-container"]}>
                  <AsyncSelect
                    label={t("breaking-news.link-story-field-label")}
                    placeholder={t("breaking-news.link-story-field-placeholder")}
                    value={breakingNews.metadata["linked-story"] ? breakingNews.metadata["linked-story"] : null}
                    loadOptions={debouncedPublishedStoriesAPI}
                    getOptionLabel={(story: api.LinkedStory) => story.headline}
                    getOptionValue={(story: api.LinkedStory) => story.id}
                    onChange={this.linkedStoryChangeHandler}
                  />
                </div>
              )}
              {linkType && linkType.value === linkTypeValues.CREATE_ASSOCIATED_STORY && (
                <React.Fragment>
                  <NonAutoFillableStoryFields
                    fieldsSpec={this.props.fieldsSpec}
                    storyTemplateFields={this.props.storyTemplateFields}
                    onStoryTemplateFieldChange={this.storyTemplateFieldChangeHandler}
                    errors={
                      this.props.validationErrors &&
                      (this.props.validationErrors["associated-story"] as NonAutoFillableStoryFieldsValidationErrors)
                    }
                    sections={this.props.sections}
                    hasTagCreatePermission={hasTagCreatePermission}
                    storyAttributes={this.props.storyAttributes}
                    isFreeTextAttributesEnabled={this.props.isFreeTextAttributesEnabled}
                    getAttributesOfTypeEntityOptions={this.props.getAttributesOfTypeEntityOptions}
                    onAttributeChange={this.props.onAttributeChange}
                  />
                  <div className={cx("breaking-news-form-field-container", "breaking-news-advanced-fields-button")}>
                    <Button
                      testId="breaking-news-advanced-options"
                      type="secondary"
                      onClick={this.advancedOptionsHandler}>
                      {t("breaking-news.show-advanced-options")}
                    </Button>
                  </div>
                  <AdvancedOptionsInspector
                    isActive={this.props.showAdvancedOptionsInspector}
                    fieldsSpec={this.props.fieldsSpec}
                    storyTemplateFields={this.props.storyTemplateFields}
                    associatedStoryDefaults={this.props.associatedStoryDefaults}
                    errors={
                      this.props.validationErrors &&
                      (this.props.validationErrors["associated-story"] as api.ValidationErrors<
                        api.StoryValidationFields
                      >)
                    }
                    storyTemplateFieldChangeHandler={this.storyTemplateFieldChangeHandler}
                    saveStoryTemplateFields={this.props.saveStoryTemplateFields}
                    cancelAdvancedOptionsSave={this.props.cancelAdvancedOptionsSave}
                  />
                </React.Fragment>
              )}
              {isSocialAccountsAvailable && (
                <React.Fragment>
                  <div className={styles["breaking-news-form-field-container"]}>
                    <Checkbox
                      label={t("breaking-news.show-social-accounts")}
                      id="show-social-accounts"
                      checked={this.props.showSocialAccounts}
                      onChange={showSocialAccountsFn}
                    />
                  </div>
                  {showSocialAccounts && (
                    <div className={cx("breaking-news-form-field-container", "breaking-news-social-accounts")}>
                      {socialAccounts.map((account, index) => (
                        <div
                          className={styles["breaking-news-social-account-container"]}
                          key={`social-account-checkbox-${index}`}>
                          <SocialAccountCheckbox
                            account={account}
                            id={index}
                            disabled={false}
                            checked={account.selected}
                            onChange={(selected: boolean) =>
                              this.socialAccountsChangeHandler(index, { ...account, selected })
                            }
                          />
                        </div>
                      ))}
                    </div>
                  )}
                </React.Fragment>
              )}
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: PartialAppState) => {
  return {
    breakingNews: state.breakingNews.breakingNews,
    breakingNewsTypes: state.config["breaking-news-types"],
    isBreakingNewsAssociateStoryEnabled: state.features.isBreakingNewsAssociateStoryEnabled,
    linkType: state.breakingNews.ui.linkType,
    fieldsSpec: state.config["template-fields"] ? state.config["template-fields"].story.all.config.fields : {},
    fieldLimits: state.config.fieldLimits,
    sections: state.config.sections,
    storyTemplateFields: state.breakingNews.app.storyTemplateFields,
    savedStoryTemplateFields: state.breakingNews.app.savedStoryTemplateFields,
    associatedStoryDefaults: state.config["associated-story-defaults"],
    validationErrors: state.breakingNews.app.validationErrors,
    isSocialAccountsAvailable: state.breakingNews.isSocialAccountsAvailable,
    socialAccounts: state.breakingNews.socialAccounts,
    showSocialAccounts: state.breakingNews.ui.showSocialAccounts,
    hasTagCreatePermission: state.features.hasTagCreatePermission,
    storyAttributes: state.config.storyAttributes,
    isFreeTextAttributesEnabled: state.features.isFreeTextAttributesEnabled
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<PartialAppState, any, AnyAction>) => {
  const navigate = navigateFn(dispatch);

  return {
    updateBreakingNews: (breakingNews: api.BreakingNews) => dispatch(updateBreakingNews(breakingNews)),
    enableBreakingNewsPublish: (flag: boolean) => dispatch(enableBreakingNewsPublish(flag)),
    setLinkType: (linkType: LinkType) => dispatch(setLinkType(linkType)),
    showSocialAccountsFn: (flag: boolean) => dispatch(showSocialAccounts(flag)),
    updateSocialAccounts: (socialAccounts: SocialAccount[]) => dispatch(updateSocialAccounts(socialAccounts)),
    updateStoryTemplateFields: (StoryTemplateFields: StoryTemplateFields) =>
      dispatch(updateStoryTemplateFields(StoryTemplateFields)),
    saveStoryTemplateFields: (storyTemplateFields: PartialStoryTemplateFields) => {
      dispatch(saveStoryTemplateFields(storyTemplateFields));
      navigate(NEW_BREAKING_NEWS);
    },
    openAdvancedOptionsInspector: (storyTemplateFields: StoryTemplateFields) => {
      dispatch(updateStoryTemplateFields(storyTemplateFields));
      navigate(BREAKING_NEWS_ADVANCED_OPTIONS);
    },
    cancelAdvancedOptionsSave: () => navigate(NEW_BREAKING_NEWS),
    getAttributesOfTypeEntityOptions: (query: string, attributeValues: any) =>
      getAttributesOfTypeEntityOptionsAction(query, attributeValues),
    onAttributeChange: (key: string, value: Array<Entity | string>) =>
      dispatch(updateMandatoryStoryAttributes(key, value))
  };
};

export { BreakingNews };

export default connect(mapStateToProps, mapDispatchToProps)(BreakingNews);
