/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { wrapInList, liftListItem } from "prosemirror-schema-list";
import { Schema, Node, NodeType, MarkType } from "prosemirror-model";
import { EditorState, Transaction } from "prosemirror-state";
import { findParentNodeClosestToPos } from "./find";
// export const nop = () => true;

export function toggleList(schema: Schema, listType: NodeType) {
  function isList(node: Node) {
    return node.type === schema.nodes.bullet_list || node.type === schema.nodes.ordered_list;
  }

  const wrap = wrapInList(listType);
  const lift = liftListItem(schema.nodes.list_item);

  return (state: EditorState, dispatch: (tr: Transaction<Schema>) => void) => {
    const bulletListParentDetails = findParentNodeClosestToPos(state, "bullet_list");
    const orderedListParentDetails = findParentNodeClosestToPos(state, "ordered_list");

    const parentNodeDetails = bulletListParentDetails || orderedListParentDetails;
    const parentNode = parentNodeDetails && parentNodeDetails.node;
    const parentNodePosition = parentNodeDetails && parentNodeDetails.pos;

    if (parentNode && parentNodePosition) {
      if (parentNode.type === listType) {
        return lift(state, dispatch);
      }

      if (isList(parentNode)) {
        const tr = state.tr;
        tr.setNodeMarkup(parentNodePosition, listType);
        if (dispatch) dispatch(tr);
        return true;
      }
    }

    return wrap(state, dispatch);
  };
}

export function removeFormatting(schema: Schema, exclusions: Array<MarkType<Schema>> = []) {
  return (editorState: EditorState, dispatch: (tr: Transaction<Schema>) => void) => {
    const { from, to, empty } = editorState.selection,
      tr = editorState.tr;

    if (!empty) {
      for (const key in schema.marks) {
        const markType = schema.marks[key];
        if (!exclusions.includes(markType)) {
          tr.removeMark(from, to, markType);
        }
      }

      tr.setBlockType(from, to, schema.nodes.paragraph);

      if (dispatch) {
        dispatch(tr);
      }
    }

    return true;
  };
}
