/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import { connect } from "react-redux";
import InfiniteScrollList from "components/infinite-scroll-list/infinite-scroll-list";
import { AnyAction, compose } from "redux";
import { ThunkDispatch } from "redux-thunk";
import File from "components/icons/file";

import User from "components/icons/user";
import Clock from "components/icons/clock";
import { PushNotificationListItem } from "api/push-notification";
import { PartialAppState } from "../../state";
import { PUSH_NOTIFICATION_EDIT_PATH } from "../../routes";
import { route } from "utils/routes.utils";
import { formattedDate } from "utils";

import styles from "./push-notification-list.module.css";
import { PushNotificationId } from "api/primitive-types";
import { t } from "i18n";
import Spinner from "components/spinner/spinner";
import NoContentCard from "components/no-content-card/no-content-card";
import { loadNextPushNotifications } from "../../async-action-creators";
import { TabValue } from "../../tab-list";
import { NavLink } from "react-router-dom";

interface StateProps {
  pushNotificationList: PushNotificationListItem[];
  errors: Error | null;
  totalCount: number;
  currentTab: TabValue;
  term: string;
}

interface DispatchProps {
  route: (id: PushNotificationId) => string;
  loadMorePushNotifications: (
    term: string,
    currentTab: TabValue,
    currentPushNotifications: PushNotificationListItem[]
  ) => void;
}

interface PushNotificationRowProps {
  pushNotification: PushNotificationListItem;
  route: (id: PushNotificationId) => string;
  currentTab: TabValue;
}

const showMessage = (
  tab: TabValue,
  pushNotification: PushNotificationListItem,
  route: (id: PushNotificationId) => string
) => {
  switch (tab) {
    case TabValue.SCHEDULED:
      return (
        <NavLink
          to={route(pushNotification.id.toString())}
          className={styles["push-notification-headline"]}
          data-test-id="push-notification-headline"
          dangerouslySetInnerHTML={{
            __html: pushNotification["highlighted-headline"] || pushNotification.message
          }}></NavLink>
      );
    case TabValue.PUBLISHED:
      return (
        <h3
          className={styles["push-notification-headline"]}
          data-test-id="push-notification-headline"
          dangerouslySetInnerHTML={{
            __html: pushNotification["highlighted-headline"] || pushNotification.message
          }}
        />
      );
    default:
      return (
        <h3
          className={styles["push-notification-headline"]}
          data-test-id="push-notification-headline"
          dangerouslySetInnerHTML={{
            __html: pushNotification["highlighted-headline"] || pushNotification.message
          }}
        />
      );
  }
};

export const PushNotificationRow: React.SFC<PushNotificationRowProps> = ({ currentTab, route, pushNotification }) => (
  <div className={styles["push-notification-card"]} data-test-id="push-notification-card">
    <div className={styles["push-notification-card-content"]} data-test-id="push-notification-card-content">
      {showMessage(currentTab, pushNotification, route)}
      <div className={styles["push-notification-byline"]} data-test-id="push-notification-byline">
        <div className={styles["push-notification-authors"]} data-test-id="push-notification-authors">
          <User />
          <div className={styles["push-notification-author"]} data-test-id="push-notification-author">
            <span
              dangerouslySetInnerHTML={{
                __html: pushNotification["author-name"]
              }}
            />
          </div>
        </div>
        <div className={styles["push-notification-time-wrapper"]} data-test-id="push-notification-time-wrapper">
          <Clock />
          <time className={styles["push-notification-time"]} data-test-id="push-notification-time">
            {formattedDate(pushNotification["updated-at"])}
          </time>
        </div>
      </div>
    </div>
  </div>
);

type PushNotificationListProps = StateProps & DispatchProps;

const PushNotificationList: React.SFC<PushNotificationListProps> = ({
  route,
  pushNotificationList,
  errors,
  totalCount,
  loadMorePushNotifications,
  currentTab,
  term
}) => (
  <React.Fragment>
    <div className={styles["push-notification-tab-panel"]} data-test-id="infinite-scroll">
      <InfiniteScrollList
        dataLength={pushNotificationList.length}
        next={loadMorePushNotifications.bind(null, term, currentTab, pushNotificationList)}
        hasMore={totalCount - pushNotificationList.length > 0}
        loader={<Spinner message={t("push-notification.messages.loading-list")} />}
        endMessage={
          !errors && (
            <NoContentCard>
              <File />
              <div className={styles["no-content-message"]} data-test-id="no-content-message">
                {totalCount === 0
                  ? t("push-notification.messages.no_push_notifications")
                  : t("push-notification.messages.no_more_push_notifications")}
              </div>
            </NoContentCard>
          )
        }>
        {pushNotificationList.map((pushNotification) => (
          <PushNotificationRow currentTab={currentTab} pushNotification={pushNotification} route={route} />
        ))}
      </InfiniteScrollList>
    </div>
  </React.Fragment>
);

const mapStateToProps = (state: PartialAppState): StateProps => {
  return {
    pushNotificationList: state.pushNotification.pushNotifications,
    errors: state.pushNotification.ui.main.error,
    totalCount: state.pushNotification.totalCount,
    currentTab: state.pushNotification.ui.currentTab,
    term: state.pushNotification.ui.searchTerm
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<PartialAppState, void, AnyAction>): DispatchProps => {
  return {
    route: (id) => route(PUSH_NOTIFICATION_EDIT_PATH, { type: "scheduled", pushNotificationId: id }),
    loadMorePushNotifications: (term, currentTab, currentPushNotifications) =>
      dispatch(loadNextPushNotifications(term, currentTab, currentPushNotifications))
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(PushNotificationList);

export { PushNotificationList };
