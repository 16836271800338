/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import classnames from "classnames/bind";
import DatePicker from "react-datepicker";
import { setHours, setMinutes, isSameDay, addMinutes } from "date-fns";
import { convertToUTC } from "./utils";
import "react-datepicker/dist/react-datepicker.css";
import styles from "./date-picker.module.css";
import { t } from "i18n";

const cx = classnames.bind(styles);

class DatePickerCustom extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      minTime: this.props.maxTime || this.validMinTime(this.props.selectedDate),
      maxTime: this.props.maxTime || setHours(setMinutes(new Date(), 59), 23),
      disabled: this.props.disabled
    };
    this.handleSelect = this.handleSelect.bind(this);
  }

  handleSelect(day) {
    if (this.props.showTimeSelect) {
      this.setState({
        minTime: this.validMinTime(day)
      });
    }
  }

  validMinTime(date): Date {
    const currentDate = new Date();
    date = date || currentDate;
    if (isSameDay(new Date(date), currentDate))
      // prevent current minute from being valid selection
      return addMinutes(currentDate, 1);
    return setHours(setMinutes(date, 0), 0);
  }

  render() {
    const currentDate = new Date();
    const currentTimezoneOffset = -(currentDate.getTimezoneOffset() / 60);
    if (this.props.showTimeSelect) {
      return (
        <div className={cx("date-picker-container", this.props.classname)} data-test-id="date-picker-container">
          <DatePicker
            className={cx("input-container", { "is-disabled": this.state.disabled })}
            disabled={this.state.disabled}
            selected={this.props.selectedDate || this.state.minTime}
            onChange={(date: any) => this.props.onChange(Date.parse(date))}
            onSelect={this.handleSelect}
            showTimeSelect
            dateFormat={this.props.dateFormat || "dd/MM/yyyy h:mm aa"}
            timeIntervals={1}
            minDate={currentDate}
            maxTime={this.state.maxTime}
            minTime={this.state.minTime}
          />
          <div className={styles["time-zone"]}>
            <span>{Intl.DateTimeFormat().resolvedOptions().timeZone}:</span>
            <span>{currentTimezoneOffset}</span>
          </div>
        </div>
      );
    }
    return (
      <div className={cx("date-picker-container", this.props.classname)} data-test-id="date-picker-container">
        <DatePicker
          className={cx("input-container", { "is-disabled": this.state.disabled })}
          disabled={this.state.disabled}
          selected={this.props.selectedDate}
          onChange={(date: any) => {
            const convertedDate = this.props.dateInUTC ? convertToUTC(date) : Date.parse(date);
            this.props.onChange(convertedDate);
          }}
          dateFormat={this.props.dateFormat || "dd/MM/yyyy"}
          minDate={this.props.minDate}
          maxDate={this.props.maxDate}
          placeholderText={t("common.select-placeholder")}
        />
        {this.props.showTimeZone && (
          <div className={styles["time-zone"]}>
            <span>{Intl.DateTimeFormat().resolvedOptions().timeZone}:</span>
            <span>{currentTimezoneOffset}</span>
          </div>
        )}
      </div>
    );
  }
}

export default DatePickerCustom;
