/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import {
  assignIn,
  flatMap,
  forEach,
  get,
  groupBy,
  has,
  head,
  identity,
  isEmpty,
  mapValues,
  pick,
  pickBy,
  uniq,
  isArray
} from "lodash";
import { t } from "i18n";
import {
  AnyContribution,
  AnyStory,
  Card,
  ChildStoryElement,
  CompositeStoryElement,
  Contribution,
  Story,
  StoryElement,
  StoryElements,
  StoryElementTextSubtype,
  StoryEntity,
  StoryTag,
  UnsavedContribution,
  UnsavedStory
} from "api/story";
import { SocialCard } from "api/social";
import { getHtmlCharacterCount, getHtmlWordCount, getReduxActionLog, replaceArrayItemByIndex } from "utils";
import { Author, AuthorWithRole } from "api/author";
import { ContributorRole } from "api/route-data/story-route-data";
import { currentCardLoading, PartialAppState } from "./state";
import { CardId, ClientId, StoryElementId } from "api/primitive-types";
import { ValidationError as ValidationErrorType } from "api/breaking-news";
import { makeEditorState } from "./prosemirror/prosemirror";
import { Selection } from "prosemirror-state";
import { Schema } from "prosemirror-model";
import { ItsmanWindow } from "containers/page/page";
import { Attribute } from "../../api/story-attributes";

const w = window as ItsmanWindow;
const isWordCharacter = (char: string): boolean => /[^\s()]/.test(char || "");
const removeConsecutiveSpaces = (text: string): string => text.replace(/ {2,}/g, " ");

const handleCurlyQuotes = (text: string): string => {
  let resultChars: any = [];

  const curlyQuotes = {
    "'": ["‘", "’"],
    '"': ["“", "”"]
  };

  for (let i = 0; i < text.length; i++) {
    const char = text[i],
      replacements = curlyQuotes[char];

    if (!replacements) {
      resultChars[i] = char;
    } else {
      resultChars[i] = isWordCharacter(text[i - 1]) ? replacements[1] : replacements[0];
    }
  }

  return resultChars.join("");
};

export const formatPlainText = (text: string): string => handleCurlyQuotes(removeConsecutiveSpaces(text));

export function groupByPathFirst(array: Array<any>, path: string): { [index: string]: any } {
  return mapValues(groupBy(array, path), head);
}

export function base64ToUtf8(str: string): string {
  return decodeURIComponent(global.escape(window.atob(str)));
}

export function utf8ToBase64(str: string): string {
  return window.btoa(global.unescape(encodeURIComponent(str)));
}

export const validations = {
  hardLimit: function(field: string, fields: Object): number {
    return get(fields, [field, "validations", "hard-limit"]);
  },

  isMandatory: function(field: string, fields: Object): boolean {
    return get(fields, [field, "validations", "mandatory"]);
  },

  hidden: function(field: string, fields: Object): boolean {
    return get(fields, [field, "validations", "hidden"]);
  },

  isHidden: function(field: string, fields: Object): boolean {
    const canBeHiddenInEditor = get(fields, [field, "can-be-hidden-in-editor?"], true);
    return canBeHiddenInEditor && (this.hidden(field, fields) || false);
  }
};

const makeId = (len: number): string => {
  var text = "";
  var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

  for (var i = 0; i < len; i++) {
    text += possible.charAt(Math.floor(Math.random() * possible.length));
  }
  return text;
};

export const generateCardTemplate = (contentId: string): SocialCard => {
  return {
    isScheduled: false,
    scheduleAt: new Date().getTime(),
    contentId: contentId,
    cardId: contentId.concat(makeId(10)),
    postMessage: "",
    selectedMedia: [],
    isPosted: false
  };
};

const addClientIdToStoryElement = (storyElement: StoryElement | CompositeStoryElement | ChildStoryElement) => {
  assignIn(storyElement, {
    "client-id": "client-story-element-" + Math.random()
  });
};

const addClientIdToCard = (card: Card) => {
  assignIn(card, {
    "client-id": "client-card-" + Math.random()
  });
};

const addClientIdToTree = (story: Story | UnsavedStory) => {
  forEach(story.tree, (card) => {
    assignIn(card, { "client-id": get(story.cards, [card["content-id"], "client-id"]) });
  });
};

export const addClientIdsToStory = (story: Story | UnsavedStory): Story | UnsavedStory => {
  forEach(story.cards, addClientIdToCard);
  forEach(story["story-elements"], (storyElement, key) => {
    addClientIdToStoryElement(storyElement);
  });
  addClientIdToTree(story);
  return story;
};

export const contributionsToUnSavedContributions = (contributions: AnyContribution[]): UnsavedContribution[] => {
  return contributions
    .filter((contribution: Contribution) => contribution.authors && contribution.authors.length)
    .map((contribution: Contribution) => ({
      id: contribution["role-id"],
      "contributors-ids": contribution.authors ? contribution.authors.map((author) => author.id) : []
    }));
};

export const getElementIdFromClientId = (clientId: ClientId, storyElements: StoryElements) => {
  const storyElementData = Object.values(storyElements);
  const storyElement = storyElementData.find(
    (element: StoryElement | CompositeStoryElement | ChildStoryElement) => element["client-id"] === clientId
  );

  return storyElement && storyElement.id;
};

export const groupValidationErrors = (errors: { metadata: object; cards: object }): any => {
  const metadataErrors = errors && errors.metadata && pick(errors.metadata, "sponsored-by");
  const metadata = {
    ...pick(errors, [
      "summary",
      "sections",
      "tags",
      "author-id",
      "canonical-url",
      "custom-slug",
      "storyline-id",
      "storyline-title",
      "asana-project-id"
    ]),
    ...metadataErrors
  };

  const seo = {
    "meta-title": get(errors, "seo.meta-title"),
    "meta-description": get(errors, "seo.meta-description"),
    "story-claim-review": get(errors, "seo.claim-reviews.story.claim-review"),
    "meta-keywords": get(errors, "seo.meta-keywords")
  };

  const storyAttributes = buildStoryAttributeErrors(errors.metadata);

  const notifications = pick(errors, "push-notification");

  const editor = {
    ...pick(errors, ["headline", "subheadline"]),
    cards: errors.cards,
    "hero-image": {
      key: errors["hero-image-s3-key"],
      caption: errors["hero-image-caption"],
      attribution: errors["hero-image-attribution"],
      "alt-text": errors["hero-image-alt-text"],
      hyperlink: errors["hero-image-hyperlink"],
      metadata: errors["hero-image-metadata"]
    },
    "reference-url": errors && errors.metadata && errors.metadata["reference-url"],
    "review-rating": errors && errors.metadata && errors.metadata["review-rating"],
    "review-title": errors && errors.metadata && errors.metadata["review-title"]
  };

  return {
    inspector: {
      ...pickBy(metadata, identity),
      ...pickBy(seo, identity),
      ...pickBy(storyAttributes, identity),
      ...pickBy(notifications, identity)
    },
    alternativeInspector: get(errors, "alternative", {}),
    editor
  };
};

function buildStoryAttributeErrors(metadataErrors: object) {
  if (!metadataErrors) {
    return;
  }

  return hasNestedStoryAttributeErrors(metadataErrors)
    ? buildNestedStoryAttributeErrors(metadataErrors)
    : metadataErrors["story-attributes"];
}

function hasNestedStoryAttributeErrors(metadataErrors: object) {
  if (has(metadataErrors, ["story-attributes", "code"])) {
    return true;
  }
  // When metadata is null, story attributes error is placed under metadata object without story-attributes key
  return get(metadataErrors, ["code"]) === "nested";
}

function buildNestedStoryAttributeErrors(metadataErrors: object) {
  return has(metadataErrors, "story-attributes")
    ? pick(metadataErrors, "story-attributes")
    : { "story-attributes": metadataErrors };
}

export const cardErrorCodes = [
  "presence",
  "must-have-photo-story-element",
  "must-have-video-story-element",
  "must-have-story-element",
  "must-have-at-most-one-pinned-card"
];

const getStoryElementTypeErrors = (type: string): string => {
  switch (type) {
    case "video": {
      return t("story-editor.must-have-video");
    }
    case "question-or-answer-or-q-and-a": {
      return t("story-editor.must-have-question-answer-element");
    }
    case "summary": {
      return t("story-editor.must-have-summary-element");
    }
    default:
      return t("story-editor.must-have-a-story-element");
  }
};

export const getStoryElementLevelErrors = (code: string) => {
  switch (code) {
    case "presence":
    case "non-blank-html-string":
      return t("story-editor.story-element-empty");
    case "url":
      return t("story-editor.cta-url-invalid");
    default:
      break;
  }
};

export const isCtaElementError = (errors: object) => {
  return !!(errors["cta-title"] || errors["cta-url"]);
};

export const getCtaElementLevelErrors = (errorObject: object) => {
  const errorMessages: string[] = [];
  const titleErrorCode = get(errorObject, ["cta-title", "code"], "");
  const urlErrorCode = get(errorObject, ["cta-url", "code"], "");
  if (titleErrorCode === "presence" || urlErrorCode === "presence") {
    errorMessages.push(t("story-editor.cta-mandatory-attributes-missing"));
  }
  if (urlErrorCode === "url") {
    errorMessages.push(t("story-editor.cta-url-invalid"));
  }
  return errorMessages.join("\n");
};

export const getCardLevelErrorMessage = (cardErrors: {}) => {
  if (cardErrors["story-elements"] && isEmpty(cardErrors["story-elements"])) {
    return t("story-editor.card-cannot-be-empty");
  } else if (cardErrors["metadata"]) {
    return t("story-editor.missing_mandatory_card_attributes");
  }
};

export const getErrorMessage = (errors: ValidationErrorType | null, type?: string): string => {
  if (type) {
    return t(`story-editor.${type}`);
  } else if (errors) {
    switch (errors.code) {
      case "max-count": {
        return t("story-editor.maxCountErrorMsg", { count: errors.threshold });
      }
      case "min-count": {
        return t("story-editor.minCountErrorMsg", { count: errors.threshold });
      }
      case "non-blank-html-string": {
        return t("settings.breaking-news-defaults.errors.text.non-blank-html-string");
      }
      case "presence":
      case "nested":
        return t("story-editor.presence");
      case "duplicate-opinions":
        return t("story-editor.duplicate-opinions");
      case "url":
        return t("story-editor.url");
      case "must-have-photo-story-element":
        return t("story-editor.must-have-photo");
      case "must-have-story-element":
        return getStoryElementTypeErrors(errors.subtype || errors.type || "");
      case "must-have-at-most-one-pinned-card":
        return t("story-editor.must-have-at-most-one-pinned-card");
      default:
        break;
    }
  }
  return "";
};

export const opinionPollErrors = (errors: Array<string>) => {
  let errorObject = {};
  return errors.reduce(
    (acc, error) => {
      switch (error) {
        case "blank-topic": {
          errorObject = { ...acc, topic: { ...acc.topic, code: "presence" } };
          break;
        }
        case "topic-length": {
          errorObject = { ...acc, topic: { ...acc.topic, code: "min-count", threshold: 20 } };
          break;
        }
        case "invalid-opinion": {
          errorObject = { ...acc, opinion: { ...acc.opinion, code: "presence" } };
          break;
        }
        case "duplicate-opinions": {
          errorObject = { ...acc, opinion: { ...acc.opinion, code: "duplicate-opinions" } };
          break;
        }
      }
      return errorObject;
    },
    { topic: {}, opinion: {} }
  );
};

const getAuthorContributorRole = (contributorRoles: ContributorRole[]): ContributorRole => {
  return contributorRoles.find((role) => role.name === "Author") as ContributorRole;
};

const mapAuthorsWithContributorRole = (authors: Author[], contributorRoles: ContributorRole[]): AuthorWithRole[] => {
  const authorContributorRole: ContributorRole = getAuthorContributorRole(contributorRoles);
  return (
    authors &&
    authors.map((author: Author) =>
      author["contributor-role"] ? (author as AuthorWithRole) : { ...author, "contributor-role": authorContributorRole }
    )
  );
};

export const authorsToContributions = (authors: Author[], contributorRoles: ContributorRole[]): Contribution[] => {
  return mapAuthorsWithContributorRole(authors, contributorRoles).reduce(
    (acc: Contribution[], { "contributor-role": role, ...author }) => {
      if (role) {
        const contributionIndex = acc.findIndex((contribution) => contribution["role-id"] === role.id);
        const contributions = acc[contributionIndex]
          ? replaceArrayItemByIndex(acc, contributionIndex, {
              ...acc[contributionIndex],
              authors: acc[contributionIndex].authors.concat(author)
            })
          : acc.concat({ "role-id": role.id, "role-name": role.name, authors: [author] });

        return contributions;
      }
      return acc;
    },
    []
  );
};

export const hasEmptyStoryElementsOrCards = (story: Story | UnsavedStory): boolean => {
  const allElements = Object.values(story["story-elements"]);
  const emptyTextElements = allElements.filter(function(element) {
    return element.type === "text" && element.subtype === null && element.text === "<p></p>";
  });
  if (emptyTextElements.length > 0) {
    return true;
  }
  const allCards = Object.values(story.cards);
  var emptyCardsAvailable: boolean = false;
  allCards.forEach((card) => {
    if (card.tree.length === 0) {
      emptyCardsAvailable = true;
    }
  });
  return emptyCardsAvailable;
};

export const getUniqueMetaTags = (tags: Array<StoryTag | StoryEntity>, metaKeywords: string[] | undefined) => {
  const tagNames = tags.map((tag) => tag.name);
  if (metaKeywords) {
    return Array.from(new Set(tagNames.concat(metaKeywords)));
  } else {
    return tagNames;
  }
};

const contributionsToAuthors = (contributions: Contribution[]): Author[] => {
  return flatMap(contributions, (contribution: Contribution) => {
    const authorContributorRole = {
      "contributor-role": {
        name: contribution["role-name"],
        id: contribution["role-id"]
      }
    };
    return contribution.authors.map((author: Author) => ({ ...author, ...authorContributorRole }));
  });
};

export function annotateStoryWithAuthorInfo(state: PartialAppState, story: AnyStory) {
  let authors;
  const configDefaultAuthorsWithRole = mapAuthorsWithContributorRole(
    get(state, ["config", "new-story-defaults", "authors"]),
    get(state, ["config", "contributorRoles"])
  );
  const defaultAuthorWithRole = {
    id: state.config.member.id,
    name: state.config.member.name,
    "contributor-role": getAuthorContributorRole(state.config.contributorRoles)
  };
  if (state.features.isContributorRolesEnabled) {
    authors = contributionsToAuthors(state.storyEditor.app.storyAuthorContributions);
    authors = authors.length
      ? authors
      : isArray(configDefaultAuthorsWithRole)
      ? configDefaultAuthorsWithRole
      : [defaultAuthorWithRole];
  } else {
    authors = story.authors || configDefaultAuthorsWithRole || [defaultAuthorWithRole];
  }

  return {
    ...story,
    authors,
    "author-id": get(authors, [0, "id"], story["author-id"]),
    "author-name": get(authors, [0, "name"], story["author-name"])
  };
}

export function getCardWordCount(card: Card | undefined, storyElements: StoryElements): number | null {
  return card
    ? card.tree
        .map((elemId: string) => storyElements[elemId])
        .filter(
          (elem: StoryElement) => elem.type === "text" && elem.subtype !== StoryElementTextSubtype.Cta && elem.text
        )
        .map((elem: StoryElement) => elem.text)
        .map(getHtmlWordCount)
        .reduce((a, b) => a + b, 0)
    : null;
}

export function getCardCharacterCount(card: Card | undefined, storyElements: StoryElements): number | null {
  return card
    ? card.tree
        .map((elemId: string) => storyElements[elemId])
        .filter((elem: StoryElement) => elem.type === "text" && elem.text)
        .map((elem: StoryElement) => elem.text)
        .map(getHtmlCharacterCount)
        .reduce((a, b) => a + b, 0)
    : null;
}

export function getNextCardInStory(story: Story, cardId: CardId) {
  const index = story.tree.findIndex((card) => card["content-id"] === cardId) + 1;
  return story.tree[index] && story.tree[index]["content-id"];
}

export function getNextElementInStory(story: Story, storyElement: StoryElement): StoryElementId {
  const cardId = storyElement["card-id"];
  const cardTree = story.cards[cardId].tree;
  const index = cardTree.findIndex((elem) => elem === storyElement.id) + 1;
  const storyElementId =
    index === cardTree.length ? story.cards[getNextCardInStory(story, cardId)].tree[0] : cardTree[index];
  return storyElementId;
}

function getCardWithoutSomeElements(card: Card, unloadedElements: StoryElementId[]): Card {
  return {
    ...card,
    tree: card.tree.filter((element) => !unloadedElements.includes(element))
  };
}

export function getStoryChunk(story: Story, cardsLoaded: CardId[], currentCard: currentCardLoading) {
  if (!currentCard) {
    return {
      ...story,
      tree: story.tree.filter((tree) => cardsLoaded.includes(tree["content-id"]))
    };
  }
  const cardsAtLeastPartiallyLoaded = [...cardsLoaded, currentCard.card];
  const currentCardData = story.cards[currentCard.card];
  return {
    ...story,
    cards: { ...story.cards, [currentCard.card]: getCardWithoutSomeElements(currentCardData, currentCard.elements) },
    tree: story.tree.filter((tree) => cardsAtLeastPartiallyLoaded.includes(tree["content-id"]))
  };
}

export function getNewChunkedEditorState(
  story: Story,
  cardsLoaded: CardId[],
  currentCard: currentCardLoading,
  selection?: Selection<Schema> | null
) {
  return makeEditorState(getStoryChunk(story, cardsLoaded, currentCard), {}, selection);
}

export const getDistinctStoryElements = (storyElementIds: string[] = []): string[] => {
  const distinctStoryElementIds = uniq(storyElementIds);
  try {
    if (storyElementIds.length !== distinctStoryElementIds.length) {
      throw new Error("duplicate_story_elements");
    }
  } catch (e) {
    w.newrelic.noticeError(e, {
      errorType: "duplicate_story_elemets",
      errorDescription: "Duplicate story elements found",
      errorInfo: JSON.stringify({ storyElementIds, distinctStoryElementIds }),
      reduxActionLog: JSON.stringify({ actions: getReduxActionLog() })
    });
  } finally {
    return distinctStoryElementIds;
  }
};

export const getWebsiteLanguage = (state) => {
  return get(state, ["config", "publisher", "website", "iso-code"], "en");
};

export const getTemplateStoryAttributes = (attributes: Attribute[], storyTemplate: string, onlyMandatory?: boolean) => {
  if (isEmpty(attributes)) {
    return [];
  }
  return attributes.filter((attribute) => {
    if ((!onlyMandatory || (onlyMandatory && attribute["is-mandatory"])) && attribute.type === "story") {
      const enabledForAllTemplates =
        !has(attribute, "enabled-for-all-templates") || get(attribute, "enabled-for-all-templates");
      const enabledTemplates = get(attribute, "content-templates", []) as string[];
      return enabledForAllTemplates || enabledTemplates.includes(storyTemplate);
    }
    return false;
  });
};
