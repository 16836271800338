/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import get from "lodash/get";
import debounce from "p-debounce";
import AsyncSelect from "components/select/async";
import { getWorkspaceData } from "../../../helpers/api";
import { t } from "i18n";

class AlsoRead extends React.Component<any, any> {
  loadPublishedStories: any;
  constructor(props) {
    super(props);
    //add hero-image and caption to the fields to show hero-image
    this.loadPublishedStories = debounce(async (headline) => {
      try {
        const loadData = await getWorkspaceData({
          fields:
            "authors,author-name,author-id,content-type,q,headline,message,is-published,linked-story-id,slug,story-content-id,story-template,updated-at,id,status,publish-at,last-published-at",
          aggregation: "dashboard",
          "search-type": "latest",
          limit: 20,
          q: headline,
          "post-filter-status": "published",
          "decay-field": "updated-at"
        });
        return loadData.stories;
      } catch (error) {
        console.error("Error fetching data:", error);
        throw error;
      }
    }, 250);
  }

  onChange(linkedStory) {
    const changes = linkedStory
      ? {
          text: linkedStory.headline,
          metadata: {
            "linked-story-id": linkedStory.id,
            "linked-story": linkedStory
          }
        }
      : { text: "", metadata: {} };
    this.props.onChange(this.props.storyElement.id, changes);
  }

  render() {
    const { storyElement, isDisabled } = this.props;
    const linkedStory = get(storyElement, ["metadata", "linked-story"]);

    return (
      <div className={"also-read-preview-container"}>
        {/* Add hero image here <div>
          <img src={image["image-url"]} alt={image.title} width="100%" height="100%" />
          <span>Image not loaded</span>
        </div> */}
        <AsyncSelect
          label={t("story-editor.story-element.link-a-story")}
          placeholder={t("story-editor.story-element.type-to-search")}
          loadOptions={this.loadPublishedStories}
          value={linkedStory || {}}
          getOptionLabel={(story) => story.headline}
          getOptionValue={(story) => story.id}
          onChange={(linkedStory) => this.onChange(linkedStory)}
          isDisabled={isDisabled}
          helpText={t("story-editor.story-element.also-read-help-text")}
        />
      </div>
    );
  }
}

export default AlsoRead;
