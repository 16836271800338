/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import classnames from "classnames/bind";
import { t } from "i18n";

import { SectionTree, Section } from "api/section";
import { SectionsList } from "./sections-list";
import { ContextMenu, ContextMenuList, ContextMenuItem } from "components/context-menu/context-menu";
import { navigate, route } from "utils/routes.utils";
import { SECTION_EDIT_PATH } from "../../routes";
import Circle from "components/icons/circle";
import styles from "./sections-row.module.css";

const cx = classnames.bind(styles);

interface SectionRowProps {
  section: SectionTree;
  undeleteSection?: (section: Section) => void;
  deleteSectionWithConfirmation: (section: Section) => void;
}

interface SectionRowDispatchProps {
  changeRoute: (path: string) => void;
}

const SectionRow: React.SFC<SectionRowProps & SectionRowDispatchProps> = ({
  section,
  undeleteSection,
  deleteSectionWithConfirmation,
  changeRoute
}) => {
  const classes = cx("section-item", { "is-deleted": section["deleted-at"] });
  const sectionColor = section.data && section.data.color ? section.data.color : "#FFFFFF";
  return (
    <React.Fragment>
      <li className={styles["sections-list-item"]} data-test-id="sections-list-item">
        <div
          className={classes}
          data-test-id="section-item"
          onClick={() => changeRoute(route(SECTION_EDIT_PATH, { id: section.id }))}>
          <div className={styles["section-item-row-info"]}>
            <span className={styles["section-color-container"]}>
              <Circle
                radius={18}
                fillColor={sectionColor}
                strokeWidth={sectionColor.toUpperCase() === "#FFFFFF" ? 1 : 0}
              />
            </span>
            <span>{section["display-name"] ? section["display-name"] : section.name}</span>
          </div>
          <div id={`section-menu-${section.id}`}>
            <ContextMenu>
              <div id={`section-menu-${section.id}`} className={styles["sections-menu"]}>
                <ContextMenuList>
                  {section["deleted-at"] ? (
                    <ContextMenuItem
                      label={t("sections.cta.undelete")}
                      isDisabled={!undeleteSection}
                      onClick={() => undeleteSection && undeleteSection(section)}
                    />
                  ) : (
                    <ContextMenuItem
                      label={t("sections.cta.delete")}
                      onClick={() => deleteSectionWithConfirmation(section)}
                    />
                  )}
                </ContextMenuList>
              </div>
            </ContextMenu>
          </div>
        </div>
      </li>

      {section.children.length > 0 && (
        <li className={styles["section-list-nested-items"]}>
          <SectionsList
            sections={section.children}
            undeleteSection={section["deleted-at"] ? undefined : undeleteSection}
            deleteSectionWithConfirmation={deleteSectionWithConfirmation}
            classname={"section-list-nested-items-list"}
          />
        </li>
      )}
    </React.Fragment>
  );
};

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, any>) => ({
  changeRoute: (path: string) => dispatch(navigate(path))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SectionRow);

export { SectionRow };
