/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { AssociatedStoryDefaults, ValidationErrors, AnyImage, ErrorCode } from "api/breaking-news-defaults";
import { t } from "i18n";

export const setText = (associatedStoryDefaults: AssociatedStoryDefaults, text: string) => ({
  ...associatedStoryDefaults,
  text
});

export const setImage = (associatedStoryDefaults: AssociatedStoryDefaults, image: any): AssociatedStoryDefaults => {
  let imageData = {
    "image-url": image.url,
    "image-attribution": image.attribution,
    "image-caption": image.caption,
    "image-metadata": image.metadata,
    "alt-text": image["alt-text"]
  } as AnyImage;

  imageData = image.key
    ? { ...imageData, "image-s3-key": image.key }
    : { ...imageData, "temporary-image-key": image["temp-image-key"] };

  return {
    ...associatedStoryDefaults,
    "image-data": imageData
  };
};

export const getHeroImageFieldErrors = (errors: ValidationErrors) => {
  const defaultImageErrors = errors && errors["image-data"];

  const heroImageErrors = {};
  if (defaultImageErrors) {
    heroImageErrors["attribution"] = defaultImageErrors["image-attribution"];
    heroImageErrors["caption"] = defaultImageErrors["image-caption"];
    heroImageErrors["metadata"] = defaultImageErrors["image-metadata"];
    heroImageErrors["alt-text"] = defaultImageErrors["alt-text"];
    heroImageErrors["key"] = defaultImageErrors["code"] && defaultImageErrors;
  }

  return heroImageErrors;
};

export const getErrorMessage = (error: ErrorCode): string => {
  switch (error.code) {
    case "presence":
      return t("settings.breaking-news-defaults.errors.text.presence");
    case "non-blank-html-string":
      return t("settings.breaking-news-defaults.errors.text.non-blank-html-string");
    default:
      return "";
  }
};
