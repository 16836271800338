/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import classnames from "classnames/bind";
import { connect } from "react-redux";
import { pick, get, isEmpty } from "lodash";
import matchStoryElement from "./match-story-elements";
import { actions } from "../actions";
import Chevron from "components/icons/chevron";
import Trash from "components/icons/trash";
import ElementsToolbar from "../elements-toolbar/elements-toolbar";
import StoryElementErrors from "./story-element-errors";
import getStoryElementIcon from "./icons-list";

import styles from "./story-element.module.css";
import { computeDecorations } from "../plugins/linter";
import SpellChecker from "components/icons/spell-checker";
import { NOTIFICATION_ERROR, NOTIFICATION_INFO } from "containers/page/actions";
import { t } from "i18n";
import { selectIsDesktopSizeViewport } from "store/viewport";
import { StoryElementDirection } from "../operations/story-elements/add";
import { selectIsReadOnly } from "pages/story-editor/selectors";
import { ItsmanWindow } from "containers/page/page";
import { getReduxActionLog } from "utils";
import { AiGenerateButton } from "pages/story-editor/components/ai-generate-button/ai-generate-button";
import { getTextContent } from "pages/story-editor/operations/story-elements/util";
import { setStoryElementText } from "pages/story-editor/async-action-creators";
import { AllowedStoryFields } from "api/ai";
import AiSuggestions from "pages/story-editor/components/ai-suggestions/ai-suggestions";

const w = window as ItsmanWindow;

const cx = classnames.bind(styles);

class StoryElement extends React.PureComponent<any, any> {
  elementRef: React.RefObject<HTMLDivElement>;

  constructor(props) {
    super(props);
    this.elementRef = React.createRef();
    this.showSnackbarMessage = this.showSnackbarMessage.bind(this);
    this.state = {
      isGeneratingSummary: false,
      showAiSuggestions: false
    };
  }

  componentDidMount() {
    if (
      this.props.storyElement &&
      this.props.recentlyAddedElementId === this.props.storyElement.id &&
      this.elementRef.current
    ) {
      this.elementRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  }

  private isSpellCheckerEnabled() {
    return (
      this.props.storyElement["type"] === "text" &&
      this.props.features &&
      this.props.features["isSpellCheckerEnabled"] &&
      this.props.isDesktopSizeViewport
    );
  }

  private showSnackbarMessage() {
    if (this.props.isReadOnly) {
      return this.props.showSnackBar(t("story-editor.read_only_mode"));
    }
    if (this.props.isSyndicatedReadOnlyCard) {
      return this.props.showSnackBar(t("story-editor.syndicated-stories-read-only-mode"));
    }
  }

  private setShowAiSuggestions(value) {
    this.setState({ showAiSuggestions: value });
  }

  private setIsGeneratingSummary(value) {
    this.setState({ isGeneratingSummary: value });
  }

  render() {
    const {
      contentDOM,
      storyElement,
      isDesktopSizeViewport,
      childElements = {},
      storyElementUI = {},
      onChange,
      node,
      dispatch,
      deleteElement,
      moveElement,
      errors,
      openPhotoEditor,
      isLastElement,
      isReadOnly,
      isSyndicatedReadOnlyCard,
      editorState,
      showGenerateSummaryBtn,
      notificationError,
      setElementText
    } = this.props;

    const [Component] = matchStoryElement(node.attrs);
    if (!storyElement) {
      return null;
    }

    const storyElementName = storyElement.subtype ? storyElement.subtype : storyElement.type;
    const storyElementClasses = cx(
      "story-element-container",
      { "story-element-has-error": errors },
      { "story-element-disabled": isReadOnly || isSyndicatedReadOnlyCard }
    );

    function renderElementsToolbar(direction, classname) {
      return (
        <ElementsToolbar
          currentStoryElement={storyElement}
          addNewElementAt={direction}
          classname={cx("story-element-toolbar", classname)}
          // emptyCardId is required by ElementsToolbar but isn't available here
          emptyCardId=""
          isSyndicatedReadOnlyCard={isSyndicatedReadOnlyCard}
        />
      );
    }
    const textContent = getTextContent(editorState, storyElement);
    const maximumSummaryLength = 500;
    const setSummary = (summary: string) => {
      try {
        setElementText(storyElement, summary);
      } catch (error) {
        notificationError(t("story-editor.summary-story-element.failure"));
      }
    };

    const isGenerateSummaryEnabled = storyElement.subtype === "summary" && showGenerateSummaryBtn;

    const storyElementComponent = (
      <div
        ref={this.elementRef}
        className={storyElementClasses}
        id={storyElement.id}
        {...(isReadOnly ? { contentEditable: false } : {})}
        onClick={this.showSnackbarMessage}>
        {!isReadOnly && (
          <div
            className={styles["story-editor-story-element-actions"]}
            data-test-id="story-editor-story-element-actions">
            {this.isSpellCheckerEnabled() && (
              <div
                className={styles["story-editor-story-element-action"]}
                data-test-id="story-editor-story-element-actions-spell-check"
                contentEditable={false}
                onClick={() => {
                  dispatch({
                    type: NOTIFICATION_INFO,
                    payload: { message: t("story-editor.story-element.spell-checker-start") }
                  });
                  computeDecorations(storyElement);
                }}>
                <SpellChecker />
              </div>
            )}
            <div
              className={styles["story-editor-story-element-action"]}
              data-test-id="story-editor-story-element-actions-up"
              onClick={() => moveElement(storyElement, "UP")}>
              <Chevron variant="up" />
            </div>
            <div
              className={styles["story-editor-story-element-action"]}
              data-test-id="story-editor-story-element-actions-down"
              onClick={() => moveElement(storyElement, "DOWN")}>
              <Chevron variant="down" />
            </div>
            <div
              className={styles["story-editor-story-element-action"]}
              data-test-id="story-editor-story-element-actions-delete"
              onClick={(e) => {
                deleteElement(storyElement);
              }}>
              <Trash />
            </div>
          </div>
        )}
        <div className={styles["story-element-left-pan"]}>
          {!isReadOnly && renderElementsToolbar(StoryElementDirection.TOP, "left-pan-mobile-element")}
          {isDesktopSizeViewport && (
            <div className={styles["story-element-details"]}>
              <span>{getStoryElementIcon(storyElement)}</span>
              {isGenerateSummaryEnabled && (
                <div className={cx("ai-generate-content")}>
                  <AiGenerateButton
                    classname={cx("ai-generate-content__btn")}
                    disabled={isReadOnly}
                    loading={this.state.isGeneratingSummary}
                    onClick={() => this.setShowAiSuggestions(!this.state.showAiSuggestions)}
                    data-test-id="generate-story-element-summary"
                  />
                </div>
              )}
            </div>
          )}
          {!isReadOnly && isDesktopSizeViewport && renderElementsToolbar(StoryElementDirection.BOTTOM, "")}
        </div>

        <div className={styles["story-element-right-pan"]} data-test-id="story-element-right-pan">
          {!isDesktopSizeViewport && (
            <div className={styles["story-element-details"]}>
              <span>{getStoryElementIcon(storyElement)}</span>
              {storyElement.subtype === "summary" && showGenerateSummaryBtn && (
                <div className={cx("ai-generate-content")}>
                  <AiGenerateButton
                    classname={cx("ai-generate-content__btn")}
                    disabled={isReadOnly}
                    loading={this.state.isGeneratingSummary}
                    onClick={() => this.setShowAiSuggestions(!this.state.showAiSuggestions)}
                    data-test-id="generate-story-element-summary"
                  />
                </div>
              )}
            </div>
          )}
          <Component
            storyElementUI={storyElementUI}
            storyElement={storyElement}
            childElements={childElements}
            onChange={onChange}
            node={node}
            dispatch={dispatch}
            contentDOM={contentDOM}
            openPhotoEditor={(image, imageId) => openPhotoEditor(image, { type: storyElementName }, imageId)}
            isLastElement={isLastElement}
            isDisabled={isReadOnly}
            error={errors}
          />
          {errors && <StoryElementErrors errors={storyElement.subtype === "references" ? errors[0] : errors} />}
        </div>
        {!isReadOnly &&
          !isDesktopSizeViewport &&
          renderElementsToolbar(StoryElementDirection.BOTTOM, "element-toolbar-mobile")}
      </div>
    );

    return (
      <>
        {isGenerateSummaryEnabled ? (
          <AiSuggestions
            onGeneratingSuggestion={(generatingSuggestion: boolean) =>
              this.setIsGeneratingSummary(generatingSuggestion)
            }
            isOpen={this.state.showAiSuggestions}
            onClose={() => this.setShowAiSuggestions(false)}
            storyField={AllowedStoryFields.SUMMARY}
            characterLimit={maximumSummaryLength}
            currentFieldValue={textContent}
            onSuggestionSelect={(summary: string) => setSummary(summary)}
            onSuggestionsChange={(suggestions: string[]) => {
              if (isEmpty(textContent) && suggestions.length > 0) {
                setSummary(suggestions[0]);
              }
            }}>
            {storyElementComponent}
          </AiSuggestions>
        ) : (
          storyElementComponent
        )}
      </>
    );
  }
}

const mapStateToProps = (state: any, { node }) => {
  if (!node.attrs || !node.attrs.id || !node.attrs.type) {
    const error = new Error("Cannot render story element");
    w.newrelic.noticeError(error, {
      errorType: "story_element_render",
      errorDescription: "Failed to render story element",
      errorInfo: JSON.stringify({ node }),
      reduxActionLog: JSON.stringify({ actions: getReduxActionLog() })
    });
    throw error;
  }
  const getStoryELementTree: any = get(state, ["storyEditor", "story", "story-elements", node.attrs.id, "tree"]);
  const storyElement = state.storyEditor.story["story-elements"][node.attrs.id];
  const childElements =
    node.attrs.type === "composite" && node.attrs.id
      ? pick(state.storyEditor.story["story-elements"], getStoryELementTree)
      : undefined;

  const numberOfCards = state.storyEditor.story.tree.length;
  const lastCardId = numberOfCards > 0 ? state.storyEditor.story.tree[numberOfCards - 1]["content-id"] : null;
  const numberOfElements = lastCardId ? state.storyEditor.story.cards[lastCardId].tree.length : 0;
  const lastElementId =
    numberOfElements > 0 ? state.storyEditor.story.cards[lastCardId].tree[numberOfElements - 1] : null;

  return {
    storyElement,
    features: state.features,
    isDesktopSizeViewport: selectIsDesktopSizeViewport(state),
    childElements,
    storyElementUI: state.storyEditor.ui.storyElements[node.attrs.id],
    errors:
      state.storyEditor.ui.errors.editor.cards &&
      state.storyEditor.ui.errors.editor.cards[node.attrs["card-id"]] &&
      state.storyEditor.ui.errors.editor.cards[node.attrs["card-id"]]["story-elements"] &&
      state.storyEditor.ui.errors.editor.cards[node.attrs["card-id"]]["story-elements"][node.attrs.id],
    isLastElement: node.attrs.id === lastElementId,
    recentlyAddedElementId: state.storyEditor.ui.recentlyAddedElementId,
    isReadOnly: selectIsReadOnly(state),
    isSyndicatedReadOnlyCard: node.attrs.isCardDisabled,
    editorState: state.storyEditor.editorState,
    showGenerateSummaryBtn: get(
      state.config,
      ["ai-content-generation", "story", AllowedStoryFields.SUMMARY_STORY_ELEMENT],
      false
    )
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onChange: (id, changes) => dispatch({ type: actions.UPDATE_STORY_ELEMENT, payload: { id, changes } }),
    dispatch: dispatch,
    deleteElement: (storyElement) =>
      dispatch({ type: actions.STORY_EDITOR_DELETE_STORY_ELEMENT, payload: { storyElement } }),
    moveElement: (storyElement, direction) =>
      dispatch({ type: actions.STORY_EDITOR_MOVE_STORY_ELEMENT, payload: { storyElement, direction } }),
    openPhotoEditor: (image, imageAs, imageId) =>
      dispatch({ type: actions.OPEN_PHOTO_EDITOR, payload: { image, imageAs, imageId } }),
    showSnackBar: (message) => dispatch({ type: NOTIFICATION_INFO, payload: { message, action: null } }),
    notificationError: (message: string) => dispatch({ type: NOTIFICATION_ERROR, payload: { message } }),
    setElementText: (storyElement, text) => dispatch(setStoryElementText(storyElement, text))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(StoryElement);

export { StoryElement };
