/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { client } from "./client";

export enum EntityAttributeLevel {
  Single = "single",
  Multi = "multi"
}

export enum DataType {
  Entity = "entity",
  MultiValuedStrings = "multi-valued-strings"
}

export enum CollectionAttributesDataType {
  MultiValuedStrings = "multi-valued-strings"
}
export enum AttributeType {
  Card = "card",
  Story = "story",
  Collection = "collection"
}

export interface Attribute {
  type: AttributeType;
  name: string;
  values: string[];
  "display-name": string;
  "attribute-level"?: EntityAttributeLevel;
  "data-type": DataType;
  "is-mandatory": boolean;
  "enabled-for-all-templates"?: boolean;
  "content-templates"?: string[];
}

export interface AttributesResponse {
  "story-attributes": Attribute[];
}

export const listAttributes = (): Promise<AttributesResponse> => {
  return client
    .url("/api/story-attributes")
    .get()
    .json();
};

export const replaceAttributes = (attributes: Attribute[]): Promise<void> => {
  return client
    .url("/api/story-attributes")
    .json({ "story-attributes": attributes })
    .put()
    .res();
};
